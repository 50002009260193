import React, { Component } from 'react';
import classes from './HomePage.css';
import Aux from '../../hoc/Aux/Aux';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import SelectMode from '../../components/ModesIcon/SelectMode';

import HomeIco from '../../assets/images/home-1.png';
import QuickModeImg from '../../assets/images/quick-mode.png';
import FlowModeImg from '../../assets/images/flow-mode.png';
import GroupModeImg from '../../assets/images/group-mode.png';
import KycModeImg from '../../assets/images/kyc-mode.png';
import OfficialModeImg from '../../assets/images/official-mode.png';
import StatModeImg from '../../assets/images/stat-mode.png';



class HomePage extends Component {
    state = {
        showSideDrawer: false
    }
    componentDidMount() {
        this.props.stopRedirectHomePageReached();
    }
    render() {
        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Row}>
                        <div>
                            <img src={HomeIco} width='45px' alt="Quick Mode" />
                        </div>
                        <div>
                            <h2> HomePage </h2>
                        </div>
                    </div>
                    <div className={classes.NavigationItems}>
                        <SelectMode link='/quick-mode'>
                            <div>
                                <img src={QuickModeImg} alt="Flow Mode" />
                            </div>
                            <div>
                                <h4>Quick Mode</h4>
                                <p>QuickCheck enables users quick check of BTC
                                    address, making sure that they clean or are they
                                    tainted with returninfo of valid or invalid...</p>
                            </div>
                        </SelectMode>
                        <SelectMode link='/flow-mode'>
                            <div>
                                <img src={FlowModeImg} alt="Flow Mode" />
                            </div>
                            <div>
                                <h4>Flow Mode</h4>
                                <p>See how and when each transaction has taken place,
                                    the origin of the coins, be sure were these coins
                                    stolen or were used in an illegal trade...</p>
                            </div>
                        </SelectMode>
                        <SelectMode link='/group-mode'>
                            <div>
                                <img src={GroupModeImg} alt="Flow Mode" />
                            </div>
                            <div>
                                <h4>Group Mode</h4>
                                <p>Is enabling to know with a certain probability who is ower
                                    of certain set of addresses; and to put in evidence if the
                                    ower has hidden addresses...</p>
                            </div>
                        </SelectMode>
                    </div>
                    <div className={classes.NavigationItems}>
                        <SelectMode link='/kyc-mode'>
                            <div>
                                <img src={KycModeImg} alt="Flow Mode" />
                            </div>
                            <div>
                                <h4>Kyc Mode</h4>
                                <p>Know Your Customer mode enables access to past submitted
                                    reports published by other organization allows access,
                                    available information may vary..</p>
                            </div>
                        </SelectMode>
                        <SelectMode link='/stat-mode'><div>
                            <img src={StatModeImg} alt="Flow Mode" />
                        </div>
                            <div>
                                <h4>Stat Mode</h4>
                                <p>Stat Mode allows you to explore and search blockchains
                                    for transactions, addresses, tokens, prices and other
                                    activities taking place..</p>
                            </div>
                        </SelectMode>
                        <SelectMode link='/official-mode'>
                            <div>
                                <img src={OfficialModeImg} alt="Flow Mode" />
                            </div>
                            <div>
                                <h4>Welcome</h4>
                                <p>We specialize in creating innovative solutions
                                    to enable our users to effective implementation
                                    of an AML/CFT compliance....</p>
                            </div>
                        </SelectMode>
                    </div>
                </div>


            </Aux >)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        stopRedirectHomePageReached: (orderData, token, userId) => dispatch(actions.stopRedirect(orderData, token, userId)),
    };
};

export default connect(null, mapDispatchToProps)(HomePage);
