import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './SigninButton.css'

const signinButton = (props) => (
    <li className={classes.SigninButton}>
       <NavLink 
            to={props.link}
            exact={props.exact}
            activeClassName={classes.active}>{props.children}</NavLink>
    </li>
)
 
export default signinButton;
