import React, { Component } from 'react';
import classes from './Help.css';
import Aux from '../../../hoc/Aux/Aux';

import HelpTextWelles from './HelpText/HelpTextWelles'
import HelpTextService from './HelpText/HelpTextService'
import BlueButton from '../../../components/UI/BlueButton/BlueButton';
import { NavLink } from 'react-router-dom';
import HelpIco from '../../../assets/images/help-1.png'

class FlowMode extends Component {
    state = {
        showSideDrawer: false
    }

    render() {
        return (
            <Aux>
                <div className={classes.App}>

                    <div className={classes.Center}>
                        <div className={classes.RowH}>
                            <div>
                                <img src={HelpIco} alt="Quick Mode" />
                            </div>
                            <div>
                                <h2>HELP</h2>
                            </div>
                        </div>
                        <div className={classes.Center}>
                            <h4>How Can We Help You?</h4>
                        </div>

                        <div className={classes.Text}>
                            <h4>Describe your issue </h4>
                            <p>We’ve put together some commonly asked questions to give you more information about Welles Partners Pte Ltd and the service we offer. Please browse bellow to find what you are looking for.</p>
                            <p>If you have a question that you can’t find the answer to, please use the  <NavLink to="/send-feedback"><b>feedback</b></NavLink> page.</p>

                        </div>

                        <div className={classes.Center}>
                            <h4>Frequently Asked Questions</h4>
                            <h5>Service</h5>
                        </div>

                        <div >
                            <HelpTextService />
                        </div>

                        <div className={classes.Center}>
                            <h4>Frequently Asked Questions</h4>
                            <h5>Welles Partners</h5>
                        </div>
                        <div >
                            <HelpTextWelles />
                        </div>
                    </div>
                    <div className={classes.Width} >
                        <BlueButton onClick={() => { window.history.back() }}>Back</BlueButton>
                    </div >
                </div>
            </Aux >)
    }
}

export default FlowMode;