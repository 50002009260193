import React from 'react';
import { NavLink } from 'react-router-dom';

import WellesLogo from '../../assets/images/maintenance.png';
import classes from './Maintenance.css';




const logo = (props) => (
    <div className={classes.Logo} style={{height: props.height}}>
        <NavLink 
            to={props.link}
            exact={props.exact}
            activeClassName={classes.active}>
                <img src={WellesLogo} alt="Welles Partners Pte Ltd" />
                <p>{props.children}</p>
        </NavLink>
    </div>
);

export default logo;