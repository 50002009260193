import React from 'react';
import Aux from '../../../../hoc/Aux/Aux'
import classes from '../Privacy.css';


const termsText = (props) => (
    <Aux>
        <div className={classes.Text}>
            
            <p>Welles Partners has created the following Privacy Policy to let you know what personal information we collect, when we collect it, why we collect it and how it is used. Your privacy is very important to us. We have put in place measures to ensure that any personal information or data that we obtain from you is processed in accordance with the accepted principles of good information handling.</p>
            <p>We may update this policy from time to time. Please check this page regularly for notification of any significant changes in the way we treat your personal information. We will try to provide customers with reasonable advance notice of any proposed changes.</p>
            <p>Where the words ”Welles Partners”, ”we”, ”us” or ”our” are used in this document, they are all references to Welles Partners Pte Ltd. Where the words ”you”, ”your”, or ”yours” are used in this document, they are all references to website visitors and customers.</p>
            <p><strong>What does this privacy policy cover?</strong></p>
            <p>This policy gives you information about how we treat personal information received from our customers.</p>
            <p><strong>What is personal information?</strong></p>
            <p>Personal information is defined as information that may be used to identify a living individual, such as their title, name, address, email address and phone number.</p>
            <p><strong>How is information collected?</strong></p>
            <p>We collect personal information in the following ways:</p>
            <ol type="a">
                <li>when you visit the our website or use our services, we collect information sent to us by your computer, mobile phone, or other access device. This information may include your IP address, device information including, but not limited to, identifier, name and type, operating system, location, mobile network information and standard web log information, such as your browser type, traffic to and from our site and the pages you accessed on our website.</li>
                <li>when you visit our website, we may also use “cookies” to provide you with access to certain private areas of the website. See the “Cookies” section below for further information.</li>
                <li>if you apply to become an Welles Partners customer, we will ask you for contact information – your name, address, phone, email, Skype ID and other similar information.</li>
                <li>before permitting you to use our services, we may require you to provide additional information we can use to verify your identity or address or manage risk, such as your date of birth, personal identification documents or other information. We may also obtain information about you from third parties such as credit agencies and identity verification services.</li>
                <li>when you make a deposit or a withdrawal.</li>
                <li>telephone calls may be recorded for security and regulatory purposes and may be monitored under our quality control procedures.</li>
                <li>when our customers make use of our Welles Partners AML tool. More information on Welles Partners AML can be found in the “Welles Partners AML” section below.</li>
            </ol>
            <p><strong>How is information used?</strong></p>
            <p>Our primary purpose in collecting personal information is to provide you wit a secure, smooth, efficient, and customized experience. We may use your personal information:</p>
            <ol type="a">
                <li>when you are applying to use Welles Partners services. We may contact credit or identity reference agencies with information you provide to enable us to confirm your identity.</li>
                <li>if you call us or send us an enquiry or details via email or another method, we will use your details to respond to any request/comment you have.</li>
                <li>to process transactions and send notices about your transactions.</li>
                <li>to collect fees.</li>
                <li>to prevent potentially prohibited or illegal activity.</li>
                <li>to customize, measure, and improve Welles Partners services and the content and layout of our website and applications.</li>
                <li>to provide you with targeted marketing from time to time about our services. However we will only do this if either (i) you have given us permission to do so, or (ii) you are one of our customers and we are telling you about similar products and services to the ones you have previously purchased or asked us about. In each case you can contact us to opt out of any further marketing communications.</li>
            </ol>
            <p><strong>How is information stored and protected?</strong></p>
            <p>We store, control and process your personal information on our computers in the Singapore. We protect it by maintaining physical, electronic and procedural safeguards in compliance with applicable Singapore data protection laws. We use computer safeguards such as firewalls and data encryption, we enforce physical access controls to our buildings and files, and we authorize access to personal information only for those employees who require it to fulfil their job responsibilities.</p>
            <p><strong>How is information shared?</strong></p>
            <p>When you are applying to use Welles Partners services, we may contact credit or identity reference agencies with information you provide to enable us to confirm your identity. Apart from this, we will not rent, sell or share personal information about you with other people or non-affiliated companies without your express permission.</p>
            <p><strong>Welles Partners AML</strong></p>
            <p>We offer a service called AML which assists users to comply with anti-money laundering regulations, or otherwise prevent or detect crime (such as money laundering, fraud and theft).</p>
            <p>Users of Welles Partners AML submit personal information to us. This information may include a person’s cryptocurrency addresses, usernames, passwords, names, email addresses, addresses, gender or age. We associate this information with other information, such as information available on publicly accessible cryptocurrency transaction ledgers and blockchains, or information relating to that person’s association with known or suspected criminal individuals.</p>
            <p>We use this information to determine the level of risk of that person being involved with crime or their commission or alleged commission any offence.</p>
            <p>We inform our users of this risk level. Users can then deal with that person as they wish, having regard to that person’s risk level.</p>
            <p>If it is determined that a person’s risk level is high, that person may be denied access to certain services. In extreme cases, information relating to that person may be disclosed to law enforcement agencies or other agencies which seek to prevent or implement anti-money laundering measures.</p>
            <p><strong>Cookies</strong></p>
            <p>Our web site uses cookies – small text files stored on your computer – in two ways:</p>
            <ol type="a">
                <li>to collect system-related information, such as the type of internet browser and operating system you use, the website from which you have come to our website, the duration of individual page views, paths taken by visitors through the website, and other general information and your IP address (the unique address which identifies your computer on the internet) which is automatically recognised by our web server. This information is collected for system administration and to report aggregate information to our subcontractors and partners to enable them to provide services to us. It is statistical data about our users’ browsing actions and does not, of itself, contain any personally identifiable information. It is often not possible to identify a specific individual from this information, although for example we may be able to identify it relates to a specific individual in conjunction with other information in our control.</li>
                <li>when registered users access the private sections of our website. Cookies are used to facilitate the log in process. In this case, we may be able to identify that your login details have been used.</li>
            </ol>
            <p>Most web browsers offer users controls, to give you the option to delete or disable cookies. You can usually find out how to do so by referring to the ‘Help’ option on the menu bar of your browser, or by visiting the browser developer’s website. This will usually tell you how to prevent your browser from accepting new cookies; notify you when you receive new cookies; and disable cookies altogether. Please note that disabling cookies will stop you accessing private areas of the website.</p>
            <p><strong>Data protection laws</strong></p>
            <p>Welles Partners complies with the data protection laws of the United Kingdom, including the Data Protection Act. Your personal information is held with the strictest security protocols and policies.</p>
            <p>We are also registered as a Data Controller with the Information Commissioner’s Office. This registration is required primarily because of some of the personal information we hold. The registration number is 201917162C for Welles Partners Pte Ltd.</p>
            <p>You have the right to request a copy of the personal information we hold about you, its origin and any recipients of it as well as the purpose of any data processing carried out. Please note that, in accordance with the Data Protection Act 1998, a 10 EURO admin fee is applicable. For further information, please contact us by emailing&nbsp;<a href="mailto:info@welles.sg">info@welles.sg</a>&nbsp;with the subject “Data subject access request”.</p>
            <p>In accordance with the Data Protection Act 1998, you have the right to correct, restrict our use of or ask us to delete your personal information. Contact us at&nbsp;<a href="mailto:info@welles.sg">info@welles.sg</a>&nbsp;to ask any questions or request the correction, restriction or deletion of your personal information. If you close your account, we will keep your personal information in our database. Your personal information will not be used by us for any further purposes, nor sold or shared with third parties, except as required by law or in accordance with this Privacy Policy.</p>
        </div>
    </Aux >
)

export default termsText;