import React, {Component} from 'react';
import { Popover } from 'react-bootstrap';

 class MyPopover extends Component{
  
  render() {
    return (
      <Popover id="popover-trigger-focus" title={this.props.title} {...this.props}>
          { this.props.children }
      </Popover>
    );
  }
}

export default MyPopover;