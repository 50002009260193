import React from 'react';

import classes from './NavigationItemsFooter.css';
import NavigationItem from './NavigationItemFooter/NavigationItemFooter';


const navigationItems = () => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link="/code-of-laws">Code of Laws</NavigationItem>
        {/* <NavigationItem link="/settings">Settings</NavigationItem> */}
        <NavigationItem link="/privacy">Privacy</NavigationItem>
        <NavigationItem link="/terms">Terms</NavigationItem>
        <NavigationItem link="/help">Help</NavigationItem>
    </ul>
);

export default navigationItems;