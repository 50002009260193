import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './SelectMode.css';
import Aux from '../../hoc/Aux/Aux';

const modeIcon = (props) => (
    <li className={classes.WaffleIcon} style={{ height: props.height }}>
        <NavLink 
            to={props.link}
            exact={props.exact}
            activeClassName={classes.active}>
                
                <Aux>{props.children}</Aux>
        </NavLink>
    </li>
);

export default modeIcon;
