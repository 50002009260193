import React, { Component } from 'react';
import classes from './Laws.css';
import Aux from '../../../hoc/Aux/Aux';


import BlueButton from '../../../components/UI/BlueButton/BlueButton';
import LawIco from '../../../assets/images/law-1.png'

import LawsText from './LawsText/LawsText'
class FlowMode extends Component {
    state = {
        showSideDrawer: false
    }

    render() {

        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Center}>
                        <div className={classes.RowH}>
                            <div>
                                <img src={LawIco} alt="Quick Mode" />
                            </div>
                            <div>
                                <h2>CODE OF LAWS</h2>
                            </div>
                        </div>
                        <div className={classes.Center}>
                            <h4>Regulations and Legistlations Relevant to Crypto Compliance</h4>
                        </div>
                        <LawsText />
                    </div>

                    <div className={classes.Width} >
                        <BlueButton onClick={() => { window.history.back() }}>Back</BlueButton>
                    </div >
                </div>
            </Aux>)
    }
}

export default FlowMode;