//To do authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//To do global app Routing
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

// To send axios request and Write Qick CheckAddress to database 
export const QUICKCHECK_ADDRESS_START = 'QUICKCHECK_ADDRESS_START';
export const QUICKCHECK_ADDRESS_SUCCESS = 'QUICKCHECK_ADDRESS_SUCCESS';
export const QUICKCHECK_ADDRESS_FAIL = 'QUICKCHECK_ADDRESS_FAIL';
export const QUICKCHECK_ADDRESS_DEL = 'QUICKCHECK_ADDRESS_DEL';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';

//To recive axios request and to resivece QuickCheck Address from database
export const FETCH_ADDRESSES_START = 'FETCH_ADDRESSES_START';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_FAIL = 'FETCH_ADDRESSES_FAIL';


//Arhaic for modal to be used 
export const PURCHASE_INIT = 'PURCHASE_INIT';
export const SPINNER_START = 'SPINNER_START';
export const REDIRECT_STOP = 'REDIRECT_STOP';

//To enable wait functions
export const WAIT_START = 'WAIT_START';
export const WAIT_SUCCESS = 'WAIT_SUCCESS';
export const WAIT_FAIL = 'WAIT_FAIL';