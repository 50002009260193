import React, { Component } from 'react';
import D3Code from './d3Code/d3Code'

// import classes from './d3Code/d3Code.css';
import './d3Code/d3Code.css';


class codeWrapper extends Component {
    state = {
        playButton: false
    }

    componentDidMount() {
        D3Code(this.svg, this.buttonsvg)
    }

    buttonHandeler = () => {
        let bla = !this.state.playButton;
        this.setState({ playButton: bla })
    }

    render() {
        console.log()
        return (
                <div ref={(ref) => this.svg = ref} />
        )
    }

}

export default codeWrapper;