import React from 'react';
import Aux from '../../../hoc/Aux/Aux'

import classes from './QuickCheckListItem.css'



const QuickCheckListItem = (props) => {
    let addressesList = <div className={classes.InputElementTrue}>
        <div className={classes.Div}>
            <p> {props.addr} </p>
            <p> {props.date} </p>
            <p> {props.risk_index} </p>
            <button
                disabled={props.disabled}
                className={[classes.Button, classes[props.btnType]].join(' ')}
                onClick={props.clicked}>Delete Clean Address</button>
            <p>{props.children}</p>
        </div>
    </div>


    if (!props.result) {
        addressesList = <div className={classes.InputElementFalse}>
            <div className={classes.Div}>
                <p> {props.addr} </p>
                <p> {props.date} </p>
                <p> {props.risk_index} </p>
                <button
                    disabled={props.disabled}
                    className={[classes.Button, classes[props.btnType]].join(' ')}
                    onClick={props.clicked}>Delete Tainted Address</button>
                <p>{props.children}</p>
            </div>
        </div>
    }


    return (
        <Aux>
            <div className={classes.Address}>
                {addressesList}
            </div>
        </Aux>
    )

}
export default QuickCheckListItem;