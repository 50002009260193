import React from 'react';

import classes from './NavigationItemsFooter.css';
import NavigationItem from './NavigationItemFooter/NavigationItemFooter';
import Aux from '../../../hoc/Aux/Aux';

import HomeImg from '../../../assets/images/home.png';
// Will retrune setting in future
// import SettingsImg from '../../../assets/images/settings.png';
import LawImg from '../../../assets/images/law.png';
import PrivacyImg from '../../../assets/images/privacy.png';
import TermsImg from '../../../assets/images/terms.png';
import HelpImg from '../../../assets/images/help.png';
import FeedbackImg from '../../../assets/images/feedback.png';


const navigationItems = (props) => (
    <Aux>
        <ul className={classes.NavigationItems} >
            <NavigationItem link="/" exact show={props.show} clicked={props.clicked}>
                <img src={HomeImg} alt="Home" /> &nbsp; Home
            </NavigationItem>
            {
                /* Will return in future
                <NavigationItem link="/settings" show={props.show} clicked={props.clicked}>
                    <img src={SettingsImg} alt="Settings" /> &nbsp; Settings
                </NavigationItem> */
            }
            <NavigationItem link="/code-of-laws" show={props.show} clicked={props.clicked}>
                <img src={LawImg} alt="Code of Laws" /> &nbsp; Code of Laws
            </NavigationItem>
        </ul>
        <hr></hr>
        <ul className={classes.NavigationItems}>
            <NavigationItem link="/privacy" show={props.show} clicked={props.clicked}>
                <img src={PrivacyImg} alt="Privacy" /> &nbsp; Privacy
            </NavigationItem>
            <NavigationItem link="/terms" show={props.show} clicked={props.clicked}>
                <img src={TermsImg} alt="Terms" /> &nbsp; Terms
            </NavigationItem>
            <NavigationItem link="/help" show={props.show} clicked={props.clicked}>
                <img src={HelpImg} alt="Help" /> &nbsp; Help
            </NavigationItem>
            <NavigationItem link="/send-feedback" show={props.show} clicked={props.clicked}>
                <img src={FeedbackImg} alt="Send Feedback" /> &nbsp; Send feedback
            </NavigationItem>
        </ul>
    </Aux>
);

export default navigationItems;
