import * as d3 from 'd3';
import d3Tip from 'd3-tip';


// to START collapsed write _children
// to START uncollasredv write children
import data from '../DataSample/flare.json'
//Change in d3Code function accordingily

const d3Code = (mainMountRef, refbutton) => {


  var margin = { top: 20, right: 20, bottom: 30, left: 20 },
    width = 900 - margin.left - margin.right,
    height = 900 - margin.top - margin.bottom,
    maxRadius = (Math.min(width, height) / 2) - 5;


  let tip = d3Tip().attr('class', 'D3Tip')
    .html((d) => {
      var text = "<strong>Group Name: </strong> <span style='color:red'>" + d.data.name + "</span><br>";
      text += "<strong>Number of Entities: </strong> <span style='color:red;text-transform:capitalize'>" +  formatNumber(0.70134961834 * d.value) + "</span><br>";
      return text;
    })


  const formatNumber = d3.format(',d');

  const x = d3.scaleLinear()
    .range([0, 2 * Math.PI])
    .clamp(true);

  const y = d3.scaleSqrt()
    .range([maxRadius * .2, maxRadius]);

  const color = d3.scaleOrdinal(d3.schemeCategory10);

  const partition = d3.partition();

  const arc = d3.arc()
    .startAngle(d => x(d.x0))
    .endAngle(d => x(d.x1))
    .innerRadius(d => Math.max(0, y(d.y0)))
    .outerRadius(d => Math.max(0, y(d.y1)));

  const middleArcLine = d => {
    const halfPi = Math.PI / 2;
    const angles = [x(d.x0) - halfPi, x(d.x1) - halfPi];
    const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);

    const middleAngle = (angles[1] + angles[0]) / 2;
    const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
    if (invertDirection) { angles.reverse(); }

    const path = d3.path();
    path.arc(0, 0, r, angles[0], angles[1], invertDirection);
    return path.toString();
  };

  const textFits = d => {
    const CHAR_SPACE = 6;

    const deltaAngle = x(d.x1) - x(d.x0);
    const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);
    const perimeter = r * deltaAngle;

    return d.data.name.length * CHAR_SPACE < perimeter;
  };

  var svg = d3.select(mainMountRef)
    .append("div")
    // Container class to make it responsive.
    .classed("svg-container", true)
    .append("svg")
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr('viewBox', `${-10} ${-30} ${900} ${900}`)

    // .attr("viewBox", `0 0 ${width + margin.right + margin.left} ${height + margin.top + margin.bottom}`)
    // Class to make it responsive.
    .classed("svg-content-responsive", true)
    // .attr("width", width + margin.right + margin.left)
    // .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + (height / 2 + 10) + ")")
    .on('click', () => focusOn()); // Reset zoom on canvas click


  svg.call(tip)

  const root = d3.hierarchy(data);
  root.sum(d => d.size);

  const slice = svg.selectAll('g.slice')
    .data(partition(root).descendants());

  slice.exit().remove();

  const newSlice = slice.enter()
    .append('g').attr('class', 'slice')
    .on('click', d => {
      d3.event.stopPropagation();
      focusOn(d);
    });

  newSlice.append('title')

    .on("mouseover", tip.show)
    .on("mouseout", tip.hide)

  // .text(d => d.data.name + '\n' + formatNumber(1.39* d.value));

  newSlice.append('path')
    .attr('class', 'mainArc')
    .style('fill', d => color((d.children ? d : d.parent).data.name))
    .attr('d', arc)
    .on("mouseover", tip.show)
    .on("mouseout", tip.hide);

  newSlice.append('path')
    .attr('class', 'hiddenArc')
    .attr('id', (_, i) => `hiddenArc${i}`)
    .attr('d', middleArcLine);

  const text = newSlice.append('text')
    .attr('display', d => textFits(d) ? null : 'none');


  // Add white contour
  text.append('textPath')
    .attr('startOffset', '50%')
    .attr('xlink:href', (_, i) => `#hiddenArc${i}`)
    .text(d => d.data.name)
    .style('fill', 'none')
    .style('stroke', '#fff')
    .style('stroke-width', 5)
    .style('stroke-linejoin', 'round');

  text.append('textPath')
    .attr('startOffset', '50%')
    .attr('xlink:href', (_, i) => `#hiddenArc${i}`)
    .text(d => d.data.name);

  function focusOn(d = { x0: 0, x1: 1, y0: 0, y1: 1 }) {
    // Reset to top-level if no data point specified

    const transition = svg.transition()
      .duration(750)
      .tween('scale', () => {
        const xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
          yd = d3.interpolate(y.domain(), [d.y0, 1]);
        return t => { x.domain(xd(t)); y.domain(yd(t)); };
      });

    transition.selectAll('path.mainArc')
      .attrTween('d', d => () => arc(d));

    transition.selectAll('path.hiddenArc')
      .attrTween('d', d => () => middleArcLine(d));

    transition.selectAll('text')
      .attrTween('display', d => () => textFits(d) ? null : 'none');

    moveStackToFront(d);

    //

    function moveStackToFront(elD) {
      svg.selectAll('.slice').filter(d => d === elD)
        .each(function (d) {
          this.parentNode.appendChild(this);
          if (d.parent) { moveStackToFront(d.parent); }
        })
    }
  }






















}

export default d3Code;