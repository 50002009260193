import React from 'react';

import classes from './NavigationItemsFooter.css';
import NavigationItem from './NavigationItemFooter/NavigationItemFooter';

const navigationItems = () => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link="/send-feedback">Send feedback</NavigationItem>
    </ul>
);

export default navigationItems;