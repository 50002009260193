import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    quickcheckAddresses: [],
    loading: false,
    purchased: false,
    redirect: false
};

const startSpinner = (state, action) => {
    return updateObject(state, { loading: true });
};

const stopRedirect = (state, action) => {
    return updateObject(state, { loading: false, redirect: false });
};

const purchaseInit = (state, action) => {
    return updateObject(state, { purchased: false });
};

const quickcheckAddressStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const quickcheckAddressSuccess = (state, action) => {
    const newOrder = updateObject(action.orderData, { id: action.orderId });
    return updateObject(state, {
        loading: false,
        purchased: true,
        quickcheckAddresses: state.quickcheckAddresses.concat(newOrder)
    });
};

const quickcheckAddressFail = (state, action) => {
    return updateObject(state, { loading: false });
};

//To reserve data
const fetchAddressesStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchAddressesSuccess = (state, action) => {
    return updateObject(state, {
        quickcheckAddresses: action.quickcheckAddresses,
        loading: false,
        redirect: true
    });
};

const fetchAddressesFail = (state, action) => {
    return updateObject(state, { loading: false });
};



//To wait for data for few seconds
const waitStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const waitSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        redirect: true
    });
};

const waitFail = (state, action) => {
    return updateObject(state, { loading: false });
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SPINNER_START: return startSpinner(state, action);
        case actionTypes.REDIRECT_STOP: return stopRedirect(state, action);
        case actionTypes.PURCHASE_INIT: return purchaseInit(state, action);
        //create address action type
        case actionTypes.QUICKCHECK_ADDRESS_START: return quickcheckAddressStart(state, action);
        case actionTypes.QUICKCHECK_ADDRESS_SUCCESS: return quickcheckAddressSuccess(state, action)
        case actionTypes.QUICKCHECK_ADDRESS_FAIL: return quickcheckAddressFail(state, action);
        //delete address action type

        //wait for few seconds 
        case actionTypes.WAIT_START: return waitStart(state, action);
        case actionTypes.WAIT_SUCCESS: return waitSuccess(state, action);
        case actionTypes.WAIT_FAIL: return waitFail(state, action);

        //fetch address action type
        case actionTypes.FETCH_ADDRESSES_START: return fetchAddressesStart(state, action);
        case actionTypes.FETCH_ADDRESSES_SUCCESS: return fetchAddressesSuccess(state, action);
        case actionTypes.FETCH_ADDRESSES_FAIL: return fetchAddressesFail(state, action);
        default: return state;
    }
};

export default reducer;