import React from 'react';

import Logo from '../../Logo/Logo'
import NavigationItemsSideMenu from '../NavigationItemsFooter/NavigationItemsSideMenu'
import classes from './SideDrawer.css'
import Backdrop from '../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Aux/Aux';
import SigninButton from '../../UI/SigninButton/SigninButton';
import FollowButtonOut from '../../UI/SigninButtonToOut/SigninButtonToOut';

import config from '../../../shared/config/config';

const sideDrawer = ( props ) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }
    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ')}>
                <div className={classes.Logo} >
                    <Logo link='/' exact/>
                </div>
                {props.isAuth ?
                <nav>
                    <NavigationItemsSideMenu show={props.open} clicked={props.closed} />
                    <hr></hr>
                    <SigninButton  link="/log-out">Log out </SigninButton>
                </nav>
                : 
                <nav onClick={props.closed}>
                    <FollowButtonOut link={config.cognitoUserPool.auth}>Please Sign In to Continue</FollowButtonOut>
                </nav>}
            </div>
        </Aux>
    );
};

export default sideDrawer;