import React from 'react';
import classes from './ToolbarFooter.css'
import NavigationItemsFooterLeft from '../Navigation/NavigationItemsFooter/NavigationItemsFooterLeft';
import NavigationItemsFooterRight from '../Navigation/NavigationItemsFooter/NavigationItemsFooterRight';

const toolbarFooter = (props) => (
    <footer className={classes.ToolbarFooter}>

        <nav className={classes.DesktopOnly}>
            <NavigationItemsFooterLeft />
        </nav>
        
        <nav className={classes.DesktopOnly}>
            <NavigationItemsFooterRight />
        </nav>

    </footer>
);

export default toolbarFooter;