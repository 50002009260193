import React, { Component } from 'react';
import Aux from './hoc/Aux/Aux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';

import Layout from './hoc/Layout/Layout';

//Mode Pages Defined
import Homepage from './containers/Home/HomePage';
import FlowMode from './containers/Modes/FlowMode/FlowMode';
import GroupMode from './containers/Modes/GroupMode/GroupMode';
import KycMode from './containers/Modes/KycMode/KycMode';
import QuickMode from './containers/Modes/QuickMode/QuickMode';
import StatMode from './containers/Modes/StatMode/StatMode';
import OfficialMode from './containers/Modes/OfficialMode/OfficialMode';


//App Pages Defined
// import Settings from './containers/PagesApp/Settings/Settings';
import Laws from './containers/PagesApp/Laws/Laws';
import Privacy from './containers/PagesApp/Privacy/Privacy';
import Terms from './containers/PagesApp/Terms/Terms';
import Help from './containers/PagesApp/Help/Help';
import Feedback from './containers/PagesApp/Feedback/Feedback';
import Logout from './containers/PagesApp/Auth/Logout/Logout';
import AuthPage from './containers/PagesApp/Auth/AuthPage/AuthPage.js';


class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = (
      <Switch>
        <Route path='/privacy' component={Privacy} />
        <Route path='/terms' component={Terms} />
        <Route path="/auth-page" component={AuthPage} />
        <Route path='/official-mode' component={OfficialMode} />
        <Redirect to="/official-mode" />
      </Switch>
    );
    console.log(this.props.isAuthenticated)
    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          {/* Modes Pages */}
          <Route path='/' exact component={Homepage} />
          <Route path='/flow-mode' component={FlowMode} />
          <Route path='/group-mode' component={GroupMode} />
          <Route path='/kyc-mode' component={KycMode} />
          <Route path='/quick-mode' component={QuickMode} />
          <Route path='/stat-mode' component={StatMode} />
          <Route path='/official-mode' component={OfficialMode} />
          <Route path='/code-of-laws' component={Laws} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/terms' component={Terms} />
          <Route path='/help' component={Help} />
          <Route path='/send-feedback' component={Feedback} />
          <Route path='/log-out' component={Logout} />
          <Redirect to="/" exact />
        </Switch>
      );
    }
    console.log(this.props.isAuthenticated)
    return (
      <Aux>
        <Layout>
          {routes}
        </Layout>
      </Aux>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
