import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Redirect } from 'react-router-dom';
import Maintenance from '../../../../components/Maintenance/Maintenance';
import Aux from '../../../../hoc/Aux/Aux';


import * as actions from '../../../../store/actions/index';

import classes from './AuthPage.css'

class Auth extends Component {
    state = {
        formIsValid: false,
        chkbox: false,
    }


    render() {
        let grantType = 'authorization_code';
        let search = this.props.location.search;
        const params = new URLSearchParams(search);
        const urlCode = params.get('code');
        console.log(urlCode)

        return (
            <Aux>
                <div className={classes.Auth}>
                    <div className={classes.App}>
                        <div className={classes.AppTest}>
                            <Maintenance link='/' exact />
                            <h2>Maintenance in Progress!</h2>
                            <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.</p>
                            <p> If you need to you can always <a href="mailto:info@welles.sg">contact us</a>, otherwise some of our APP functionalities are not currently available.</p>
                            <hr></hr>
                            <font size="2"> I have read and agree to the  <NavLink to="/terms"><b>Terms and Conditions</b></NavLink> and <NavLink to="/privacy"><b>Privacy Policy</b></NavLink>.</font>

                        </div>
                        <br></br>
                        <div className={classes.SearchButton}>
                            <button onClick={() => this.props.onAuth(grantType, urlCode)}> CONTINUE</button>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onAuth: (grantType, urlCode) => dispatch(actions.authAll(grantType, urlCode)),
        // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);