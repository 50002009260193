import React from 'react';
import Aux from '../../../../hoc/Aux/Aux'
import classes from '../Laws.css';

const lawsText = (props) => (
	<Aux>
		<div className={classes.Text}>
			<p>Updates: List of regulations will updated as fequently as possible.</p>
			<article >
				<div >
					<span><h4>Swiss Regulation</h4></span>
				</div>
				<h5>
					<p>Swiss view:</p>
				</h5>
				<p>Switzerland has no regulations regarding the buying and selling of virtual currency units or their use as a means of paying for goods and services. In other words, no special approval is required for these activities. This applies to everyone who pays with a virtual currency or buys units of it as well as to all those who accept payments in a virtual currency or sell units of it. This does not mean, however, that there are no risks in using or investing in virtual currencies. As a rule, these currencies are subject to significant price fluctuation and there may be uncertainties regarding the currency issuer. At present, it remains unclear whether claims to virtual currencies can be asserted under civil law...</p>
				<ul>
					<li>Links to important publications:</li>
					<ol>
						<li><a href='https://www.vsv-asg.ch/en/home'>Swiss Association of Asset Managers | SAAM</a></li>
						<li><a href='https://www.finma.ch/en/'>FINMA Regulations</a></li>
						<li><a href='https://www.finma.ch/en/~/media/finma/dokumente/dokumentencenter/myfinma/1bewilligung/fintech/wegleitung-ico.pdf?la=en'>FINMA: ICO Guidelines: for enquiries regarding the regulatory framework for initial coin offerings (ICOs)</a></li>
						<li><a href="https://www.finma.ch/en/~/media/finma/dokumente/dokumentencenter/myfinma/4dokumentation/finma-aufsichtsmitteilungen/20170929-finma-aufsichtsmitteilung-04-2017.pdf?la=en">FINMA: Guidance 04/2017 Regulatory treatment of initial coin offerings</a></li>
						<li><a href='https://www.finma.ch/en/~/media/finma/dokumente/dokumentencenter/myfinma/finma-publikationen/geschaeftsbericht/20190404-finma-jahresbericht-2018.pdf?la=en'>FINMA: Annual Report 2018</a> </li>
						<li><a href='https://www.finma.ch/en/~/media/finma/dokumente/dokumentencenter/myfinma/faktenblaetter/faktenblatt-virtuelle-waehrungen.pdf?la=en'>FINMA: Virtual currencies</a></li>
						<li><a href='https://www.efd.admin.ch/efd/en/home/dokumentation/nsb-news_list.msg-id-73398.html'>EDF: Federal Council wants to further improve framework conditions for blockchain/DLT</a></li>
						<li><a href='https://www.sif.admin.ch/sif/en/home/dokumentation/medienmitteilungen/medienmitteilungen.msg-id-74420.html'>EDF: Federal Council initiates consultation on improving framework conditions for blockchain/DLT</a></li>
						<li><a href='https://www.newsd.admin.ch/newsd/message/attachments/56166.pdf'>EDF: National Risk Assessment (NRA): Risk of money laundering and terrorist financing posed by crypto assets and crowdfunding</a></li>
						<li><a href='https://www.swissbanking.org/en/home?set_language=en'>Swiss Bankers Association</a></li>
					</ol>
					<li>Link to News:</li>
					<ol>
						<li><a href='https://www.baerkarrer.ch/publications/BK%20Briefing-Swiss%20Federal%20Council%20Proposes%20Revisions%20to%20the%20Legal%20and%20Regulatory%20Framework%20Governing%20DLT%20and%20Blockchain%20Applications_final.pdf'>Swiss Federal Council Proposes Revisions to the Legal and Regulatory Framework Governing DLT and Blockchain Applications</a></li>
						<li><a href='https://www.letemps.ch/economie/licence-fintech-entre-vigueur'>LE TEMPS: The fintech regulation comes into force</a></li>
					</ol>
				</ul>
			</article>

			<article >
				<div >
					<span><h4>EU Regulation</h4></span>
				</div>
				<h5>
					<p>Europian view:</p>
				</h5>
				<p>More and more regulators are worrying about criminals who are increasingly using cryptocurrencies for illegitimate activities like money laundering, terrorist financing and tax evasion. The problem is significant: even though the full scale of misuse of virtual currencies is unknown, its market value has been reported to exceed EUR 7 billion worldwide...</p>
				<ul>
					<li>Links to important publications:</li>
					<ol>
						<li><a href="http://www.europarl.europa.eu/cmsdata/150761/TAX3%20Study%20on%20cryptocurrencies%20and%20blockchain.pdf">Europian parlament: Cryptocurrencies and blockchain</a>.</li>
						<li><a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A52018DC0109">COMMUNICATION FROM THE COMMISSION TO THE EUROPEAN PARLIAMENT, THE COUNCIL, THE EUROPEAN CENTRAL BANK, THE EUROPEAN ECONOMIC AND SOCIAL COMMITTEE AND THE COMMITTEE OF THE REGIONS FinTech Action plan with ANNEX: - COM/2018/0109 final</a></li>
					</ol>
				</ul>
			</article>

			<article >
				<div >
					<span><h4>Singaporian Regulation</h4></span>
				</div>
				<h5>
					<p>Singaporian view:</p>
				</h5>
				<p>More and more regulators are worrying about criminals who are increasingly using cryptocurrencies for illegitimate activities like money laundering, terrorist financing and tax evasion. The problem is significant: even though the full scale of misuse of virtual currencies is unknown, its market value has been reported to exceed EUR 7 billion worldwide...</p>
				<ul>
					<li>Links to important publications:</li>
					<ol>
						<li><a href='https://www.mas.gov.sg/~/media/MAS/News%20and%20Publications/Monographs%20and%20Information%20Papers/Guide%20to%20Digital%20Token%20Offerings%20last%20updated%20on%2030%20Nov.pdf'>MAS: A GUIDE TO DIGITAL TOKEN OFFERINGS</a></li>
						<li><a href='https://www.mas.gov.sg/news/parliamentary-replies/2019/reply-to-parliamentary-question-on-digital-payment-tokens'>MAS: Reply to Parliamentary Question on Digital Payment Tokens (mandatory compliacne with AML/CFT)</a></li>
						<li><a href='https://www.finma.ch/en/'>FINMA Regulations</a></li>
						<li><a href='https://www.swissbanking.org/en/home?set_language=en'>Swiss Bankers Association</a></li>
						<li><a href='https://www.iras.gov.sg/IRASHome/GST/GST-registered-businesses/Specific-business-sectors/e-Commerce/#title5'>Common e-Commerce Items(Cryptocurrencies are treated as goods)</a></li>
					</ol>
					<li>Link to News:</li>
					<ol>
						<li><a href='http://fintechnews.sg/tag/kryptos-alpha-fund/'>First Crypto Fund in Singapore.</a></li>

					</ol>

				</ul>
			</article>

			<article >
				<div >
					<span><h4>International Regulation</h4></span>
				</div>
				<h5>
					<p>IOSCO view:</p>
				</h5>
				<p>The emergence of crypto-assets is an important area of interest for regulatory authorities, including those with authority over secondary markets and the trading platforms that facilitate the secondary trading of crypto-assets (Crypto-Asset Trading Platforms or CTPs). The aim of this Consultation Report is to assist IOSCO members in evaluating the issues and risks relating to CTPs...</p>
				<ul>
					<li>Links to important publications:</li>
					<ol>
						<li><a href='https://www.iosco.org/library/pubdocs/pdf/IOSCOPD627.pdf'>IOSCO:Consultation Report - Issues, Risks and Regulatory Considerations Relating to Crypto-Asset Trading Platforms </a></li>
					</ol>

				</ul>
			</article>


		</div>
	</Aux >
)

export default lawsText;