import React from 'react';
import Aux from '../../../../hoc/Aux/Aux'
import classes from '../Help.css';

const helpText = (props) => (
	<Aux>
		<div className={classes.Text}>
			<p>Commonly raised questions about Welles Service. </p>
			<article >
				<div >
					<span><h4>1. Question</h4></span>
				</div>
				<h5>
					<p>How do I use QuickCheck Mode?</p>
				</h5>
				<p>QuickCheck offers quick solution to question is my address tainted:</p>
				<ul>
					<li>How to check my address?</li>
					<p>We are doing this by building group of addresses. Each address in the group is described by li probability which give the odds that they are owned by the same entity. This is done in accordence to our Forensics Database.</p>
					<li>Steps to take:</li>
					<ol>
						<li>Open Quick Mode.</li>
						<li>In seach field eneter your address Id.</li>
						<li>Wait for response.</li>
					</ol>
				</ul>
			</article>
			<article >
				<div >
					<span><h4>2. Question</h4></span>
				</div>
				<h5>
					<p>When rest of the modes will be available?</p>
				</h5>
				<p>We are working on developling all the service ASAP:</p>
				<ul>
					<li>Roadmap?</li>
					<p>Please look on our <a href='https://welles.sg'>official website</a> our Roadmap. Plan is to enable modes in new releases:</p>
					<ol>
						<li>Quick Mode.</li>
						<li>Flow Mode.</li>
						<li>Group Mode.</li>
						<li>KYC Mode.</li>
					</ol>
				</ul>
			</article>
		</div>
	</Aux >
)

export default helpText;