import React, { Component } from 'react';
import classes from './Terms.css';
import Aux from '../../../hoc/Aux/Aux';

import TermsText from './TermsText/TermsText'
import BlueButton from '../../../components/UI/BlueButton/BlueButton';
import TermsIco from '../../../assets/images/term-1.png'

class FlowMode extends Component {
    state = {
        showSideDrawer: false
    }

    render() {
        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Center}>
                        <div className={classes.RowH}>
                            <div>
                                <img src={TermsIco} alt="Quick Mode" />
                            </div>
                            <div>
                                <h2>TERMS</h2>
                            </div>
                        </div>
                        <div className={classes.Center}>
                            <h4>Terms and Conditions | Welles Partners Pte Ltd </h4>
                        </div>
                        <TermsText />
                    </div>

                    <div className={classes.Width} >
                        <BlueButton onClick={() => { window.history.back() }}>Back</BlueButton>
                    </div>
                </div>
            </Aux>)
    }
}

export default FlowMode;