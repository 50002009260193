import React from 'react';
import Aux from '../../../../hoc/Aux/Aux'
import classes from '../Help.css';

const helpText = (props) => (
    <Aux>
        <div className={classes.Text}>
            <p>Select from the following list of FAQs and browse through these FAQs to find answers to commonly raised questions. </p>
            <article >
                <div>
                    <span><h4>1. Question</h4></span>
                </div>
                <h5>
                    <p>What problem exactly is Welles trying to solve with Blockchain Technologies in CRYPTO finance industry?</p>
                </h5>
                <ul>
                    <p>Problems we want to solve are:</p>
                    <li>How to qualify Addresses and respectively Coins?</li>
                    <p>We want to qualify ledger data in good and bad players, based on this we reconstruct flows of clean and tainted coins.</p>
                    <li>How do we correlate addresses to owners?</li>
                    <p>We are doing this by building group of addresses. Each address in the group is described by a probability which give the odds that they are owned by the same entity.</p>
                    <li>Our customer gets:</li>
                    <ul>
                        <li>Guarantees that he will not be sued as he is building compliance in his processes.</li>
                        <li>Knowledge that crypto-deposits he considers accepting or owning are legal or not.</li>
                    </ul>
                </ul>

            </article>


            <article>
                <div>
                    <span><h4>2. Question</h4></span>
                </div>
                <h5>
                    <p>What are the functionalities given by the Crypto-Compliance mode?</p>
                </h5>
                <ul>
                    <p>It will give new capabilities to our customer:</p>
                    <li>Enables individuals who possess crypto assets to know whether it has been through dirt, and to know its degree of compliance.</li>
                    <li>Enables anyone who sends/receives cryptocurrency to/from someone to know if address he is interacting with, is suspicious and if it could coexist with tainted coins.</li>
                    <li>Enables making groups to which individual users may be correlated to sets of addresses. These addresses come in three categories: detected, found and suspected. They are assigned probabilities of them being owned by the same individual in the group mode.</li>
                    <li>Enables users not to spend efforts multiple times on checking compliance of certain individuals. Entities can publish the data base of individuals whose compliance they had assessed. This data can be shared and accessed given the right level of privileges which is provided in KYC mode.</li>
                    <li>Enables everyone to store his WhiteList in our block chain, and get a signature and a time stamp to prove that at the moment of publishing/receiving the crypto-assets, these were legal. As this can change with time, as some owners in a few years may be judged for involment in criminal activities, with the outcome that all of theirs assets will be marked accordingly by court decisions.</li>
                </ul>

            </article>

            <article>
                <div>
                    <span><h4>3. Question</h4></span>
                </div>
                <h5>
                    <p>Who is our customer?</p>
                </h5>
                <ul>
                    <p>Compliance service in the close future is going to be implemented as an integral part of the backend of any serious wallet. At least in a cost-efficient and rudimentary level like in our <b>QuickCheck</b> service. </p>
                    <p>Soon there will have be just a choice which solution to use:</p>
                    <li>is it compliance service provided by us</li>
                    <li>or compliance service by our competitors.</li>
                    <p>This mechanism will automatically give warnings to coin owners, which is a safe way of doing transactions. Compliance service is costing much less than transaction fee.</p>

                    <p>Customers exist! They need to be notified that we offer solution to their problem:</p>
                    <ul>
                        <li>Individual user</li>
                        <li>Crypto Exchanges.</li>
                        <li>Entities that accept crypto assets.</li>
                        <li>Investment funds - Crypto</li>
                        <li>Crypto Investment management</li>
                        <li>Crypto Hedge fund management</li>
                        <li>Crypto Custody services</li>
                        <li>Users &#38; Organizers of ICO IPO STO platforms</li>
                        <li>Small Entrepreneurs - Crypto</li>
                        <li>Small Wealth manager - Crypto</li>
                        <li>Investment managers - Crypto</li>
                        <li>Private banking - Crypto</li>
                        <li>Hedge fund management - Crypto</li>
                        <li>Custody services - Crypto</li>
                        <li>Private equity funds - Crypto</li>
                    </ul>
                </ul>

            </article>


            <article>
                <div>
                    <span><h4>4. Question</h4></span>
                </div>
                <h5>
                    <p>What is the market opportunity?</p>
                </h5>
                <ul>
                    <li>Individual User:</li>
                    <p>For a standard user we offer the QuickCheck service to assess the legality of his assets with only two possible outcomes (GOOD, BAD). If the user wants to learn more, he can purchase the full report. </p>

                    <li>ICO/STO management:</li>
                    <p>It is really hard for ICO/STO management to be sure that their whitelist is compliant, plus they do it by themselves while probably they do not have enough information or time to build their whitelist in the right way. We offer an easy way to be compliant with law and not accepting suspicious assets.</p>

                    <p>ICOs and STOs are perfect targets for money laundering and terrorist funding:</p>
                    <p>ICOs have a high risk of unknowingly doing money laundering as they change an established cryptocurrency to a newly created cryptocurrency. New cryptocurrencies in most cases are not monitored; they thus are perfect targets.</p>

                    <li>User Interface:</li>
                    <ul>
                        <li>If they use Welles services, they can just  upload csv file of addresses, and they will get in return two files, one of good addresses and one of bad ones.</li>
                        <li>For more details they can order a section report or a full report.</li>
                    </ul>
                    <li>Crypto-exchanges:</li>
                    <p>Exchanges are a great place for money laundering as they have NO need to be compliant with any law, and they do not care for it. In the near future this will change as Regulatory bodies collect more knowledge and get more tools and will enforce regulations.</p>

                    <li>User Interface:</li>
                    <ul>
                        <li>Accessing our API will enable them to check every single address before accepting it into their system, and thus stay compliant.</li>
                    </ul>
                    <li>Regulatory bodies</li>
                    <p>They will a tool to enforce compliance with new regulations for all entities in the crypto currency world. Regulatory bodies need a stable partner to help them implement compliance with laws on their systems. We offer solutions to both of these problems.</p>

                </ul>

            </article>

            <article>
                <div>
                    <span><h4>5. Question</h4></span>
                </div>
                <h5>
                    <p>Why Now Is It The Moment ?</p>
                </h5>
                <ul>
                    <p>Compliance is work intensive and relies more and more on computer technology.</p>

                    <li>Two main sources of innovation made this possible:</li>
                    <ul>
                        <li>Development of cryptocurrencies which introduces open distributed ledger of transactions, and which is a good start for complex analysis.</li>
                        <li>Banks are becoming more open and regulated in Western countries, as of 2017, to open their transaction data to licensed companies, as part of anti-terrorist and crime fighting efforts.</li>
                    </ul>
                    <p>We are one of the pioneers in the area. All big transfers of cryptocurrencies are bound to use service like ours or it will be too risky, not to say impossible, to perform without assurance of compliance of the assets.</p>

                    <p>CONCLUSION: This product is necessary in financial markets, and it will reshape the future of compliance. It will be used extensively in cryptocurrencies. </p>
                </ul>

            </article>

            <article>
                <div>
                    <span><h4>6. Question</h4></span>
                </div>
                <h5>
                    <p>How Welles can solve that problem?</p>
                </h5>
                <ul>
                    <p>Welles collects informations from multiple sources, of two categories</p>
                    <ul>
                        <li>Data that we gather by our algorithms and investigation work.</li>
                        <li>Data published by institutions and regulatory bodies as proven bad assets</li>
                    </ul>
                    <p>Customer will know which kind of data was used to determine compliance of owner address, if it originates from a regulatory body or from our investigation work.</p>
                    <ul>
                        <li>Flow Mode and Group Mode are based on this data and developed in cooperation with academia. Algorithms follow clear and tainted coins flows, and group addresses into owner clusters.</li>
                        <li>KYC mode is for our customers to access owners for which compliance status is already proven, as to save them time and efforts; they will know if owner was previously determined as compliant or not by any other organization.</li>
                    </ul>
                </ul>
            </article>

            <article>
                <div>
                    <span><h4>7. Question</h4></span>
                </div>
                <h5>
                    <p>What is the Welles roadmap?</p>
                </h5>
                <ul>
                    <p>How is it divided into several fundraising phases, which each phase having its own “RunWay”? Look for details at <a href="https://welles.fr/company.html">Welles DevOps roadmap</a></p>
                </ul>
            </article>

            <article>
                <div>
                    <span><h4>8. Question</h4></span>
                </div>
                <h5>
                    <p>Welles business model?</p>
                </h5>
                <ul>
                    <li>Request access to Welles Business Plan. <a href="https://docs.google.com/document/d/1xG8c8-VIeLtqwISTUnjuMsolAYhLWSYba2Icw5kiapw">Business Plan</a></li>
                </ul>
            </article>

            <article>
                <div>
                    <span><h4>9. Question</h4></span>
                </div>
                <h5>
                    <p>Welles monetization strategy?</p>
                </h5>
                <ul>
                    <p>Pay for usage.</p>
                    <li>Flexible pricing offer:</li>
                    <ul>
                    <li>- customers pay just for what they used.</li>
                    <li>- if they use services more pay they less.</li>
                    </ul>
                    <p>To know more request access to monetization strategy here <a href="https://docs.google.com/document/d/12WgZRhr21QGAmJrM0wYBVU0GuKLoFdB4hmQYBN47tZQ/edit?usp=sharing">Monetization Strategy</a>.</p>
                </ul>
            </article>
        </div>
    </Aux >
)

export default helpText;