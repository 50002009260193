import React, { Component } from 'react';
import classes from './Privacy.css';
import Aux from '../../../hoc/Aux/Aux';

import PrivacyText from './PrivacyText/PrivacyText'
import BlueButton from '../../../components/UI/BlueButton/BlueButton';
import PrivacyIco from '../../../assets/images/privacy-1.png'

class FlowMode extends Component {
    state = {
        showSideDrawer: false
    }

    render() {

        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Center}>
                        <div className={classes.RowH}>
                            <div>
                                <img src={PrivacyIco} alt="Quick Mode" />
                            </div>
                            <div>
                                <h2>PRIVACY</h2>
                            </div>
                        </div>
                        <div className={classes.Center}>
                            <h4>Privacy Policy | Welles Partners Pte Ltd </h4>
                        </div>
                        <PrivacyText />
                    </div>

                    <div className={classes.Width} >
                        <BlueButton onClick={() => {window.history.back()}}>Back</BlueButton>
                    </div >
                </div>
            </Aux>)
    }
}

export default FlowMode;