import React from 'react';
import classes from './WaffleButton.css';
import WaffleImg from '../../../assets/images/waffle.png';
import SelectMode from '../../ModesIcon/SelectMode';

import MyPopover from '../Popover/Popover';
import { OverlayTrigger } from 'react-bootstrap';

import QuickModeImg from '../../../assets/images/quick-mode.png';
import FlowModeImg from '../../../assets/images/flow-mode.png';
import GroupModeImg from '../../../assets/images/group-mode.png';
import KycModeImg from '../../../assets/images/kyc-mode.png';
import OfficialModeImg from '../../../assets/images/official-mode.png';
import StatModeImg from '../../../assets/images/stat-mode.png';


const popoverFocus = (
    <MyPopover id="tooltip-trigger-focus" >
        <ul className={classes.NavigationItems}>
            <SelectMode link='/quick-mode'>
                <div>
                    <img src={QuickModeImg} alt="Flow Mode" />
                </div>
                <div>Quick Mode</div>
            </SelectMode>
            <SelectMode link='/flow-mode'>
                <div>
                    <img src={FlowModeImg} alt="Flow Mode" />
                </div>
                <div>Flow Mode</div>
            </SelectMode>
            <SelectMode link='/group-mode'>
                <div>
                    <img src={GroupModeImg} alt="Flow Mode" />
                </div>
                <div>Group Mode</div>
            </SelectMode>
        </ul>
        <ul className={classes.NavigationItems}>
            <SelectMode link='/kyc-mode'>
                <div>
                    <img src={KycModeImg} alt="Flow Mode" />
                </div>
                <div>Kyc Mode</div>
            </SelectMode>
            <SelectMode link='/stat-mode'><div>
                <img src={StatModeImg} alt="Flow Mode" />
            </div>
                <div>Stat Mode</div>
            </SelectMode>

            <SelectMode link='/official-mode'>
                <div>
                    <img src={OfficialModeImg} alt="Flow Mode" />
                </div>
                <div>Welcome</div>
            </SelectMode>
        </ul>
    </MyPopover >
);

const waffleButton = (props) => (
    <li className={classes.Waffle} style={{ height: props.height }}>
        <OverlayTrigger 
        trigger={['click'] }
        placement="bottom" 
        overlay={popoverFocus}>
            <div>
            <button href="#top" ><img src={WaffleImg} alt="manu" /></button>
            </div>
        </OverlayTrigger>
    </li>
);

export default waffleButton;