import React from 'react';
import Aux from '../../../../hoc/Aux/Aux'
import classes from '../Terms.css';


const termsText = (props) => (
    <Aux>
        <div className={classes.Text}>
        <h5>1. INTERPRETATION </h5>
        <p>1.1. To the extent not defined in this Clause 1, the terms outlined in an Engagement Letter shall have the meaning given to them therein. </p>
        <p>1.2. A <b>“person”</b> includes a natural person, corporate or unincorporated body. </p>
        <p>1.3. Any reference to these Terms terminating shall, where the context requires, include a reference to these Terms terminating by expiry of the Term. </p>
        <p>1.4. Unless the context otherwise requires, words in the singular include the plural and in the plural include the singular and references to one gender are references to the other genders. </p>
        <p>1.5. A reference to a statute of statutory provision is a reference to it as it is in force for the time being, taking account of any amendment, extension, or re-enactment and includes any subordinate legislation for the time being in force made under it. </p>
        <p>1.6. The words <b>“include”</b> and <b>“including”</b> (or similar) shall be construed as illustrative only and shall not limit the sense of the description, definition, phrase or term(s) that comes before the relevant term. </p>
        <p>1.7. Any reference to an English legal term for any action, remedy, method or judicial proceedings legal document, legal status, court, official, or any other legal concept shall, in respect of any other jurisdiction than Singapore be deemed to include the legal term which most nearly approximates in that jurisdiction to the English legal term. </p>
        <p>1.8. Any reference to the <b>“Parties”</b> means Welles Partners Pte Ltd and Customer and <b>“Party”</b> shall mean one of them. </p>

        <h5>2. COMMENCEMENT AND DURATION </h5>
        <p>2.1. Unless terminated earlier pursuant to the terms of Clause 19, these Terms shall: </p>
        <ol type="a">
            <li> commence on the Effective Date and shall continue for the Initial Term; and </li>
            <li> automatically renew for further successive Renewal Terms at the end of the Initial Term and at the end of each Renewal Term, unless either Party gives written notice to the other Party not later than ninety (90) days before the end of the Initial Term or a Renewal Term (as applicable) to terminate these Terms at the end of the Initial Term or that Renewal Term (as applicable). </li>
        </ol>
        <h5>3. CHANGES TO THESE TERMS </h5>
        <p>3.1. Welles Partners Pte Ltd may amend the Agreement on thirty (30) days’ notice to Customer. During such notice period, Customer may terminate the Agreement immediately on written notice to Welles Partners Pte Ltd. </p>
        <p>3.2. Any access to or use of AML/CFT Platform or Forensics Platform following expiry of the above notice period shall constitute Customer’s irrevocable agreement to the Agreement as amended by Welles Partners Pte Ltd pursuant to Clause 3.1. </p>

        <h5>4. FEES </h5>
        <p>4.1. Fees are payable as agreed by the Parties in the relevant Engagement Letter. Where the relevant Engagement Letter does not confirm how Fees are to be paid, the Fees shall be paid quarterly in advance. </p>
        <p>4.2. Welles Partners Pte Ltd will issue an invoice for the Fees to the Customer. Unless otherwise agreed in the relevant Engagement Letter, invoices are payable within thirty (30) days of the invoice date. </p>
        <p>4.3. In addition to any other rights or remedies of Welles Partners Pte Ltd, if the Customer fails to make any payments by the due date or otherwise in accordance with the Agreement: </p>
        <ol type="a">
         <li> any portion of any sums that is not paid when due and payable will accrue interest equal to three percent (3%) per annum above the base lending rate from time to time of OCBC bank Singapore, accruing on a daily basis and being compounded quarterly, from the time the indebtedness arose, with interest on all overdue interest accruing at the same rate and calculated and payable in the same manner until fully paid, whether before or after judgment; and </li>
         <li> Welles Partners Pte Ltd may immediately suspend the provision of any access to the AML/CFT Platform, the Forensics Platform and/or any other services provided. </li>
        </ol>
        <p>4.4. All amounts referred to in the Agreement are exclusive of taxes and similar assessments, which shall be added to such amounts in the relevant invoice. </p>
        <p>4.5. As between the Parties, Customer shall be liable for any sales, use, excise, value-added, services, consumption and other taxes and duties on amounts payable by Customer in respect of any services supplied or provided by Welles Partners Pte Ltd to Customer. </p>
        <p>4.6. On termination of the Agreement, Welles Partners Pte Ltd will issue a final invoice to Customer in respect of all outstanding sums payable by Customer under the Agreement in respect of which an invoice has not been issued. The Customer’s obligation to pay any such final invoice pursuant to this Clause 4.6 shall survive expiry or termination of the Agreement. </p>

        <h5>5. ACCESS TO AND USE OF THE AML/CFT PLATFORM AND FORENSICS PLATFORM </h5>
        <p>5.1. Subject to Customer’s and its Authorised Users’ continuing compliance with the Agreement (including payment of all Fees due and payable in accordance with Clause 4): </p>
        <ol type="a">
            <li> where an Engagement Letter permits the Customer to access and use the AML/CFT Platform, Welles Partners Pte Ltd hereby grants Customer a personal, non-exclusive, non- transferable, non-sublicensable, revocable (on termination) right for its Authorised Users to access and use the features and functions of the AML/CFT Platform via its web interface during the Term to the extent strictly necessary for the Customer to examine the provenance of Cryptocurrency for the purposes of assessing the risk of a Cryptocurrency transaction being associated with illicit activity, as part of Customer’s internal operations; </li>
            <li> where an Engagement Letter permits the Customer to access and use the Forensics Platform, Welles Partners Pte Ltd hereby grants Customer a personal, non-exclusive, non- transferable, non-sublicensable, revocable (on termination) right for its Authorised Users to access and use the features and functions of the Forensics Platform via its web interface during the Term to the extent strictly necessary for the re-identification of pseudonymous Cryptocurrency users and map chains of Cryptocurrency transactions, as part of the Customer’s internal operations; </li>
            <li> where an Engagement Letter permits the Customer to access and use both the AML/CFT Platform and the Forensics Platform, Welles Partners Pte Ltd hereby grants to the Customer the rights set out in sub-clauses 5.1(a) and 5.1(b); </li>
            <li> in all cases, Welles Partners Pte Ltd hereby grants to the Customer a personal, non-exclusive, non-transferable non- sublicensable, revocable (on termination) right for its Authorised Users to access and use the Documentation applicable to the relevant licence(s) above. </li>
        </ol>
        <p>5.2. The licenses outlined in Clause 5.1 are subject to the terms of the AML/CFT Platform Package and/or Forensics Platform Package (as applicable) as set out in the relevant Engagement Letter. The Customer acknowledges that the functionality of the AML/CFT Platform and/or Forensics Platform (as applicable) shall reflect the functionality of the AML/CFT Platform and/or Forensics Platform (as applicable) on the date of the relevant Engagement Letter. If Welles Partners Pte Ltd introduces new functionality or material upgrades to existing functionality of the AML/CFT Platform and/or Forensics Platform (as applicable) during the Term, Welles Partners Pte Ltd reserves the right to charge additional sums in addition to the Fees for the use of the new functionality. </p>
        <p>5.3. Customer acknowledges and agrees that prior to accessing AML/CFT Platform or Forensics Platform, any relevant Authorised User shall be required to accept the Acceptable Use Policy. </p>
        <p>5.4. Customer is solely responsible for all activities that Authorised Users undertake on AML/CFT Platform and Forensics Platform. Customer shall immediately notify Welles Partners Pte Ltd of any unauthorised use of any Authorised User’s Access Protocols. </p>
        <p>5.5. Welles Partners Pte Ltd will not be liable for any Losses arising from Customer’s failure to ensure each Authorised User maintains the confidentiality of its Access Protocols. </p>
        <p>5.6. In relation to the Authorised Users, Customer undertakes that: </p>
        <ol type="a">
            <li> it shall ensure that: </li>
            <ol type="i">
                <li> only Authorised Users access AML/CFT Platform and Forensics Platform (as applicable); and </li>
                <li> each Authorised User maintains the confidentiality of the Access Protocols; and </li>
            </ol>
            <li> it will not allow or suffer any Access Protocols allocated to an Authorised User to be used by more than one individual Authorised User; and </li>
            <li> it shall immediately notify Welles Partners Pte Ltd if any Authorised User ceases to be employed or otherwise engaged by Customer. </li>
        </ol>
        <p>5.7. Welles Partners Pte Ltd may modify, suspend or discontinue any part of AML/CFT Platform or Forensics Platform or otherwise make any changes it considers desirable to the AML/CFT Platform and the Forensics Platform. In the event that any such modification, suspension, discontinuance or change of any part of AML/CFT Platform or Forensics Platform creates a material adverse effect on the Customer, the Customer may terminate the Agreement on seven (7) days’ written notice to Welles Partners Pte Ltd, provided that the Customer provides such notice within fourteen (14) days of such modification, suspension, discontinuance or change taking effect. </p>

        <h5>6. OWNERSHIP </h5>
        <p>6.1. As between the Parties, Welles Partners Pte Ltd retains sole ownership of all right, title and interest, including all Intellectual Property Rights, in and to AML/CFT Platform, the Forensics Platform, the Documentation and the Deliverables. </p>
        <p>6.2. Customer will not acquire any right, title or interest in or to AML/CFT Platform or Forensics Platform (as applicable), except as expressly provided in the Agreement. </p>
        <p>6.3. Welles Partners Pte Ltd reserves all rights in and to AML/CFT Platform and Forensics Platform not expressly granted in the Agreement. </p>
        <p>6.4. Customer will indemnify, defend and hold Welles Partners Pte Ltd and its officers, directors, employees and agents harmless from and against any and all Losses arising from or in connection with or relating directly to Customer exceeding the scope of any licence, right or permission to use AML/CFT Platform, the Forensics Platform, the Documentation or the Deliverables (or any part thereof) granted to it under the Agreement. </p>

        <h5>7. USE OF DELIVERABLES </h5>
        <p>7.1. Subject to Customer’s and its Authorised Users’ continuing compliance with the Agreement (including payment of all Fees due and payable in accordance with Clause 4), Welles Partners Pte Ltd hereby grants Customer a personal, non-exclusive, non-transferable, non- sublicensable, perpetual right for it to use the Deliverables as part of Customer’s internal operations only. </p>
        <p>7.2. The Customer shall not use or disclose the Deliverables in relation to any civil proceedings, criminal proceedings or regulatory investigations. Where the Customer either: (i) is compelled to disclose the Deliverables (or any part thereof) pursuant to any civil proceedings, criminal proceedings or regulatory investigations, or (ii) discloses the Deliverables (or any part thereof) in breach of this Clause 7.2, the Customer agrees that it shall: </p>
        <ol type="a">
            <li> give Welles Partners Pte Ltd reasonable advance notice of such disclosure in respect of a compelled disclosure; </li>
            <li> use its best endeavours to seek all necessary orders, permissions or other actions as may be necessary or desirable to Welles Partners Pte Ltd to keep confidential all Confidential Information contained in the Deliverables; and </li>
            <li> indemnify, defend and hold Welles Partners Pte Ltd and its officers, directors, employees and agents harmless from and against any and all Losses arising from or in connection with the disclosure, including but not limited to all management time and costs incurred by Welles Partners Pte Ltd’s in respect of Welles Partners Pte Ltd Personnel attending court, co- operating with a regulator or other related matters arising as a result of the disclosure. </li>
        </ol>
        <h5>8. RESTRICTIONS </h5>
        <p>8.1. Notwithstanding anything to the contrary in these Terms, no agents, consultants or contractors that Customer may engage from time to time that are employed by any third party that Welles Partners Pte Ltd reasonably deems to be its competitor shall be permitted to: </p>
        <ol type="a">
            <li> access the AML/CFT Platform or Forensics Platform; or </li>
            <li> receive or access any Deliverables. </li>
        </ol>
        <p>8.2. Without affecting any other right or remedy available to it, in the event that Welles Partners Pte Ltd determines (acting reasonably) that any Authorised User is employed by any third party that Welles Partners Pte Ltd reasonably deems to be its competitor, it may: </p>
        <ol type="a">
            <li> deactivate the user account of that Authorised User and disable any associated Access Protocols; and </li>
            <li> permanently and irretrievably destroy the user account of the deactivated Authorised User and all Customer Data stored therein. </li>
        </ol>
        <p>8.3. Customer shall not, nor shall it instruct or permit, procure, enable or request any third party (including its personnel, staff and contractors) to, take any action designed or intended to: </p>
        <ol type="a">
            <li> use the AML/CFT Platform, the Forensics Platform, the Deliverables or the Documentation (or any part thereof) in any manner or for any purpose that is inconsistent with the Agreement; </li>
            <li> provide or otherwise make the AML/CFT Platform, the Forensics Platform, the Deliverables or the Documentation (or any part thereof) available to any third parties other than to Authorised Users; </li>
            <li> use the AML/CFT Platform, the Forensics Platform, the Deliverables or the Documentation (or any part thereof) to: </li>
            <ol type="i">
                <li> create, market or distribute any product or service that is competitive with either the AML/CFT Platform or the Forensics Platform (or any part thereof); or </li>
                <li> act as a service bureau on behalf of, or otherwise provide processing or services support to, any third party; </li>
            </ol>
            <li> introduce to the AML/CFT Platform or Forensics Platform any <b>“back door,”</b> <b>“drop dead device,”</b> <b>“time bomb,”</b> <b>“Trojan horse,”</b> <b>“virus,”</b> or <b>“worm”</b> (as such terms are commonly understood in the software industry) or any other equivalent code, software routine or instructions designed or intended to disrupt, disable, harm or otherwise impede in any manner the operation of AML/CFT Platform or Forensics Platform or any device or system owned or controlled by Welles Partners Pte Ltd or any third party, or which otherwise may damage or destroy any data or file; </li>
            <li> modify, copy, resell, rent, lease, sub-licence, load, merge, adapt or translate the whole or any part of the AML/CFT Platform or the Forensics Platform (or any part thereof); </li>
            <li> contest, challenge or otherwise make any claim or take any action adverse to Welles Partners Pte Ltd’s ownership of, or interest in, the AML/CFT Platform, the Forensics Platform, the Deliverables or the Documentation (or any part thereof); </li>
            <li> re-use, disseminate, copy, or otherwise use the AML/CFT Platform, the Forensics Platform, the Deliverables or the Documentation (or any part thereof) in a way that infringes, misappropriates, or violates any Intellectual Property Rights or other right of Welles Partners Pte Ltd or any third party; </li>
            <li> remove, alter, obscure Welles Partners Pte Ltd’s trade mark, copyright notice or any other proprietary notice from the AML/CFT Platform, the Forensics Platform, the Deliverables or the Documentation (or any part thereof); </li>
            <li> interfere with the proper working of the AML/CFT Platform or Forensics Platform; </li>
            <li> circumvent, disable, or interfere with security-related features of the AML/CFT Platform or Forensics Platform or features that prevent or restrict use, access to, or copying the AML/CFT Platform or Forensics Platform, or that enforce limitations on use of the AML/CFT Platform or Forensics Platform; </li>
            <li> impose (or which may impose, in Welles Partners Pte Ltd’s sole discretion) an unreasonable or disproportionately large load on the AML/CFT Platform or Forensics Platform; or </li>
            <li> reverse engineer, decompile, unbundle, disassemble, or create derivative works based on, the whole or any part of the AML/CFT Platform or Forensics Platform unless, and to the limited extent that, applicable laws of Customer’s jurisdiction require Welles Partners Pte Ltd to give Customer the right to do so to obtain information necessary to render the AML/CFT Platform or Forensics Platform interoperable with other software; provided, however, that Customer must first request such information from Welles Partners Pte Ltd, and Welles Partners Pte Ltd may (in its sole discretion) either provide such information to Customer or impose reasonable conditions on such use of the source code for the AML/CFT Platform or Forensics Platform to ensure that Welles Partners Pte Ltd and its licensors’ proprietary rights in the source code for the AML/CFT Platform and the Forensics Platform are protected. </li>
        </ol>

        <h5>9. WARRANTIES </h5>
        <p>9.1. Subject to Clause 13 and Clause 21.1, Welles Partners Pte Ltd warrants that during the Term, the AML/CFT Platform and Forensics Platform will materially conform to the then-current Documentation when used in accordance therewith and the terms of the Agreement. </p>
        <p>9.2. The warranty set forth in Clause 9.1 shall not apply: </p>
        <ol type="a">
            <li> in the event that Customer or any third party has breached any of the restrictions outlined in Clause 8; or </li>
            <li> to any defects or issues arising as a result of any use of AML/CFT Platform or Forensics Platform in combination with other products, hardware, equipment, software, or data not expressly authorised by Welles Partners Pte Ltd to be used with AML/CFT Platform or Forensics Platform. </li>
        </ol>
        <p>9.3. Welles Partners Pte Ltd’s sole liability, and Customer’s sole remedy, for breach of the warranty in Clause 9.1 shall be Welles Partners Pte Ltd’s use of commercially reasonable efforts to remedy defects covered by such warranty within sixty (60) days of receipt of notice of such defect or, at Welles Partners Pte Ltd’s option, a refund of the sums paid by Customer for the defective element of AML/CFT Platform or Forensics Platform on a pro-rated basis. </p>
        <p>9.4. Welles Partners Pte Ltd further warrants that if any additional services are performed by Welles Partners Pte Ltd pursuant to an Engagement Letter, these services will be performed with reasonable care and skill consistent with industry standards and practices. </p>
        <p>9.5. The Customer acknowledges and agrees that the AML/CFT Platform and Forensics Platform have not been developed specifically for the Customer and are offered on an <b>“as is”</b> basis only with Welles Partners Pte Ltd offering no warranties (express or implied) that the AML/CFT Platform, the Forensics Platform, the Documentation or the Deliverables shall provide the Customer with the outcome it may seek. </p>
        <p>9.6. Each Party warrants and represents on an ongoing basis that it has, and undertakes that it shall continue to have for the duration of these Terms the requisite power, capacity and authority to enter into the Agreement and to carry out the obligations under the Agreement. </p>

        <h5>10. CUSTOMER DEPENDENCIES </h5>
        <p>10.1. In order to permit Welles Partners Pte Ltd to provide Authorised Users with access to the AML/CFT Platform and/or the Forensics Platform (as applicable) in accordance with the terms of these Terms, the Customer shall ensure that: </p>
        <ol type="a">
            <li> all Authorised Users have installed a supported version of the internet browsers and operating systems that are listed in the Documentation as supported by Welles Partners Pte Ltd from time to time for the purposes of installing and accessing the AML/CFT Platform and/or the Forensics Platform (as applicable); </li>
            <li> it has an internet connection with adequate bandwidth for Authorised Users to access and use the AML/CFT Platform and/or the Forensics Platform (as applicable); </li>
            <li> it shall maintain connectivity to the extent necessary to prevent network performance degradation; </li>
            <li> it shall implement and maintain effective security policies and procedures to prevent unauthorised disclosure of Access Protocols and unauthorised access to the AML/CFT Platform and/or the Forensics Platform (as applicable); </li>
            <li> it shall obtain or procure all authorisations, instructions and/or permissions as may be necessary for Welles Partners Pte Ltd to provide Authorised Users with access to the AML/CFT Platform and/or the Forensics Platform (as applicable); and </li>
            <li> it shall make available to Welles Partners Pte Ltd its employees and other staff members (in such number and with such level of seniority) as may be reasonably required in the circumstances to address any issue with the implementation, provision or cessation of access to the AML/CFT Platform and/or the Forensics Platform (as applicable).  Welles Partners Pte Ltd shall be relieved from any failure to comply with its obligations to provide Authorised Users with access to the AML/CFT Platform and/or the Forensics Platform (as applicable), if and to the extent that Welles Partners Pte Ltd can demonstrate that such failure was caused by Customer’s failure to meet any obligation or dependency on Customer outlined in this Clause 10 above. </li>
        </ol>
        
        <h5>11. CUSTOMER DATA </h5>
        <p>11.1. As between the Parties, all Intellectual Property Rights in and to Customer Data shall remain the property of the Customer and Welles Partners Pte Ltd shall not obtain any right, title or interest in the Customer Data, except that Welles Partners Pte Ltd shall be permitted to use the Customer Data in accordance with the licences granted in Clause 11.2. </p>
        <p>11.2. Customer hereby grants to Welles Partners Pte Ltd: </p>
        <ol type="a">
            <p> a non-exclusive, worldwide, royalty-free licence, irrevocable during the Term, to use the Customer Data for the purposes described in and anticipated by the Agreement for Welles Partners Pte Ltd to provide the services described in the Agreement; and </p>
            <p> a non-exclusive, worldwide, irrevocable, perpetual, sublicensable, royalty-free licence to use the Customer Data in the conduct of Welles Partners Pte Ltd’s business operations, including: </p>
            <ol type="i">
                <p> for the purposes of integrating the Customer Data with the AML/CFT Platform, the Forensics Platform and its other product offerings including its consultancy services in order to continually improve the quality of information Welles Partners Pte Ltd can provide to the Customer and other customers; </p>
                <p> for its data analysis and research operations. </p>
            </ol>
        </ol>
        <p>11.3. Welles Partners Pte Ltd shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data caused by any third party. </p>
        <p>11.4. Customer warrants and represents on an ongoing basis that, and undertakes that throughout the Term it shall have, the necessary rights, power, consents and authority to transmit Customer Data to Welles Partners Pte Ltd under, and in the fashion described in, these Terms and to grant Welles Partners Pte Ltd the licences to use Customer Data in Clause 11.2. </p>
        <p>11.5. Customer will indemnify, defend and hold Welles Partners Pte Ltd and its officers, directors, employees and agents harmless from and against any and all Losses arising from or in connection with, Customer’s breach of the warranty, representation and/or undertaking given in Clause 11.4. </p>

        <h5>12. DATA PROTECTION </h5>
        <p>12.1. Welles Partners Pte Ltd and the Customer shall comply with their respective obligations in respect of data protection as set out in the Schedule to these Terms (Data Processing Addendum). </p>

        <h5>13. THIRD PARTY CONTENT </h5>
        <p>13.1. Certain elements of the AML/CFT Platform and the Forensics Platform may permit or enable Customer and/or its Authorised Users to search for, find, store, manage, access or use Third Party Content. </p>
        <p>13.2. Customer acknowledges that Welles Partners Pte Ltd does not warrant, represent, endorse, support or guarantee the completeness, truthfulness, accuracy, reliability, performance, fitness for purpose or any other attributes of any Third Party Content, nor shall Welles Partners Pte Ltd be responsible for reviewing or attempting to verify the accuracy or currency of any Third Party Content. </p>
        <p>13.3. Customer acknowledges that: </p>
        <ol type="a">
            <li> Customer and/or its Authorised Users may be required to enter into certain agreements with the owner(s) or licensor(s) of Third Party Content; and </li>
            <li> in the event the relevant agreements are not entered into, Customer and/or its Authorised Users may be unable to access: </li>
                <ol type="i">
                    <li> such Third Party Content; and/or </li>
                    <li> any or all of those elements of the AML/CFT Platform and/or the Forensics Platform that permit or enable Customer and/or its Authorised Users to search for, find, store, manage, access or use such Third Party Content. </li>
                </ol>
        </ol>
        <p>13.4. To the fullest extent permitted by law, Customer expressly disclaims any and all express or implied terms of any nature relating to Third Party Content. </p>
        <p>13.5. As between Customer and Welles Partners Pte Ltd, Customer is solely responsible for: </p>
        <ol type="a">
            <li> any Third Party Content installed in, used with or accessed via the AML/CFT Platform and/or the Forensics Platform; </li>
            <li> determining the suitability of any Third Party Content for its intended use by Customer; and </li>
            <li> as necessary for its intended use, verifying the authenticity and accuracy of the Third Party Content prior to using it. </li>
        </ol>

        <h5>14. INTELLECTUAL PROPERTY RIGHTS INDEMNITY </h5>
        <p>14.1. Subject to the remainder of this Clause 14, Welles Partners Pte Ltd shall indemnify Customer from and against Losses incurred by Customer as a result of amounts awarded in judgment or settlement of any third party claim or proceeding against Customer that Customer’s use of the AML/CFT Platform or the Forensics Platform (as applicable), within the scope of the rights of use granted to Customer under these Terms, infringes the Intellectual Property Rights of a third party (“IPR Claims”). </p>
        <p>14.2. Customer shall notify Welles Partners Pte Ltd in full, accurate and complete detail in writing promptly after it becomes aware of any event or any allegation, claim, demand, proceeding or other action, which it believes may give rise to a claim for indemnification under Clause 14.1 (an <b>“Indemnified Claim”</b>). </p>
        <p>14.3. Customer shall: </p>
        <ol type="a">
            <li> allow Welles Partners Pte Ltd sole authority to control the defence and settlement of any Indemnified Claim; </li>
            <li> provide Welles Partners Pte Ltd with all reasonable cooperation in the defence of such Indemnified Claim; and </li>
            <li> not settle or compromise any Indemnified Claim or make any admission of liability without the express prior written consent of Welles Partners Pte Ltd. </li>
        </ol>
        <p>14.4. Notwithstanding Clause 14.1 or any provision of these Terms to the contrary, Welles Partners Pte Ltd shall have no liability whatsoever to Customer in respect of any IPR Claims based on or arising directly or indirectly as a result of: </p>
        <ol type="a">        
            <li> Customer exceeding the scope of any right, licence or consent to use the AML/CFT Platform and/or the Forensics Platform (as applicable) under these Terms; </li>
            <li> any use of the AML/CFT Platform and/or the Forensics Platform (as applicable) not in accordance with these Terms or the Documentation; </li>
            <li> a breach by Customer or any third party of any of the restrictions outlined in Clause 8; </li>
            <li> any use of the AML/CFT Platform and/or the Forensics Platform (as applicable) in combination with other products, hardware, equipment, software or data not expressly authorised by Welles Partners Pte Ltd to be used with the AML/CFT Platform and/or the Forensics Platform (as applicable); </li>
            <li> use of any release of any element of the AML/CFT Platform and/or the Forensics Platform (as applicable) other than the most current release made available to Customer; or </li>
            <li> any modification of the AML/CFT Platform and/or the Forensics Platform (as applicable) by any person other than Welles Partners Pte Ltd or its expressly authorised agents or any third party that performs any element of the services described in these Terms for or on behalf of Welles Partners Pte Ltd. </li>
        </ol>

        <h5>15. CONFIDENTIAL INFORMATION </h5>
        <p>15.1. In these Terms, <b>“Confidential Information”</b>: means the AML/CFT Platform, the Forensics Platform, any Deliverables, any screenshots of the operation of the AML/CFT Platform or the Forensics Platform, any of Welles Partners Pte Ltd or its Affiliates’ methodologies, any methodologies of the AML/CFT Platform or Forensics Platform, and any information that is clearly labelled or identified as confidential or ought reasonably be treated as being confidential. Confidential Information excludes any information which: </p>
        <ol type="a">
            <li> is or becomes publicly known other than through a breach of these Terms; </li>
            <li> was in the receiving Party’s lawful possession before the disclosure; </li>
            <li> is lawfully disclosed to the receiving Party by a third party without restriction on disclosure; </li>
            <li> is independently developed by the receiving Party and that independent development can be shown by written evidence; or </li>
            <li> is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body. </li>
        </ol>
        <p>15.2. Each Party will hold the other’s Confidential Information in confidence and not make the other’s Confidential Information available to any third party unless that third party is subject to an equivalent duty of confidentiality. Neither Party will use the other’s Confidential Information for any purpose other than the implementation of these Terms. </p>
        <p>15.3. Each Party will take all reasonable steps to ensure that the other’s Confidential Information to which it has access is not disclosed or distributed by its employees, agents or independent contractors in breach of the terms of these Terms. </p>
        <p>15.4. This Clause 15 will survive termination of these Terms. </p>

        <h5>16. NON-SOLICITATION </h5>
        <p>16.1. During the Term, and for a further twelve (12) months following termination, Customer agrees that it shall not directly or indirectly employ or engage (without Welles Partners Pte Ltd’s prior written agreement), or solicit for such employment or engagement, any of Welles Partners Pte Ltd’s employees, agents, consultants or contractors who have been engaged in the performance of these Terms, whether or not such person would commit a breach of contract by reason of leaving service or office. </p>

        <h5>17. LIMITATION OF LIABILITY </h5>
        <p>17.1. Nothing in the Agreement limits or excludes liability of Customer to pay Welles Partners Pte Ltd any sums due under the Agreement. </p>
        <p>17.2. This Clause 17 sets out Welles Partners Pte Ltd’s entire financial liability (including any liability for the acts or omissions of its employees, agents, consultants or contractors) to the Customer: </p>
        <ol type="a">
            <li> arising under or in connection with the Agreement; </li>
            <li> in respect of any use made by the Customer of the AML/CFT Platform and/or the Forensics Platform (as applicable), any services or any part of them; </li>
            <li> in respect of any Deliverables or any reliance on or conclusions drawn from any such Deliverables; and </li>
            <li> in respect of any representation, misrepresentation (whether innocent or negligent), statement or tortious act or omission (including negligence) arising under or in connection with these Terms. </li>
        </ol>
        <p>17.3. Except as expressly and specifically provided in these Terms, Welles Partners Pte Ltd disclaims all warranties and conditions express or implied, including, but not limited to, implied warranties of satisfactory quality and fitness for a particular purpose, in relation to the AML/CFT Platform, the Forensics Platform, any services, their use and the results of such use. </p>
        <p>17.4. Nothing in these Terms limits or excludes the liability of either Party for: </p>
        <ol type="a">
            <li> death or personal injury caused by its negligence; </li>
            <li> fraud or fraudulent misrepresentation; </li>
            <li> any indemnities given under these Terms by either Party; or </li>
            <li> any other act, omission, or liability which may not be limited or excluded by applicable law. </li>
        </ol>
        <p>17.5. Subject to Clause 17.4, Welles Partners Pte Ltd disclaims all warranties: </p>
        <ol type="a">
            <li> in respect of the compliance of services with any law, regulation (including financial services regulation) or code of best practice applicable to the Customer; </li>
            <li> that the use or operation of the AML/CFT Platform or the Forensics Platform will be uninterrupted or error-free; </li>
            <li> that defects will be corrected; </li>
            <li> that there are no viruses or other harmful components; </li>
            <li> that the security methods employed will be sufficient; and </li>
            <li> regarding correctness, accuracy, currency, reliability or any other attributes of the Deliverables. </li>
        </ol>
        <p>17.6. Subject to Clause 17.4, Welles Partners Pte Ltd shall not in any circumstances be liable to Customer whether in contract, tort (including for negligence), breach of statutory duty (howsoever arising), misrepresentation (whether innocent or negligent), restitution or otherwise, for: </p>
        <ol type="a">
            <li> any loss (whether direct or indirect) of profits, business, business opportunities, revenue, turnover, reputation or goodwill; </li>
            <li> any loss or corruption (whether direct or indirect) of data or information; </li>
            <li> loss (whether direct or indirect) of anticipated savings or wasted expenditure (including management time); </li>
            <li> the Customer’s failure to comply with any applicable law, regulation (including financial services regulation) or code of best practice as a result of its use of the AML/CFT Platform or Forensics Platform; </li>
            <li> any use of any Access Protocols by any third party; </li>
            <li> any loss or liability (whether direct or indirect) under or in relation to any other contract; or </li>
            <li> any Losses arising directly or indirectly as a result of Customer’s, or any third party’s use of, interpretation of, or reliance upon, the Deliverables. </li>
        </ol>
        <p>17.7. Subject to the remainder of this Clause 17, Welles Partners Pte Ltd’s total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation (whether innocent or negligent), restitution or otherwise, arising in connection with the performance or contemplated performance of the Agreement shall not exceed an amount equal to one hundred per cent (100%) of all sums paid by Customer in accordance with and pursuant to this Agreement in the twelve (12) month period prior to the month in which the most recent event giving rise to liability occurred. </p>

        <h5>18. THIRD PARTY RELIANCE INDEMNITY </h5>
        <p>18.1. Customer agrees to indemnify, defend and hold Welles Partners Pte Ltd and its officers, directors, employees and agents harmless from and against any and all Losses arising from or in connection with any allegation, claim, demand, proceeding or other action brought against Welles Partners Pte Ltd that is based upon, or arises directly or indirectly as a result of, Customer’s, or any third party’s use of, or reliance upon any Deliverables. </p>

        <h5>19. TERMINATION </h5>
        <p>19.1. Either Party may terminate these Terms and any associated Engagement Letter(s) in accordance with Clause 2.1(b). </p>
        <p>19.2. Welles Partners Pte Ltd may terminate these Terms and any associated Engagement Letter(s) with immediate effect if the Customer is, or Welles Partners Pte Ltd reasonably suspects the Customer is, in breach of any of the restrictions imposed on it under Clause 8.3. </p>
        <p>19.3. These Terms and any associated Engagement Letter(s) can be immediately terminated by Welles Partners Pte Ltd if it suspects that the Customer is in breach of any of its warranties or undertakings. </p>
        <p>19.4. Without affecting any other right or remedy available to it, either Party may terminate these Terms and any associated Engagement Letter(s) with immediate effect by giving notice to the other Party if the other Party: </p>
        <ol type="a">
            <li> commits a material breach of any term of the Agreement which breach is irremediable or (if such breach is remediable) fails to remedy that breach within a period of thirty (30) days after being notified to do so; or </li>
            <li> becomes insolvent or unable to pay its debts (as defined in section 123 of the Insolvency Act 1986), proposes a voluntary arrangement, has a receiver, administrator or manager appointed over the whole or any part of its business or assets, suffers the presentation of any petition, the making of any order shall or the passing of any resolution for its winding up (except for the purposes of a bona fide solvent amalgamation or reconstruction), bankruptcy or dissolution, otherwise proposes or enters into any composition or arrangement with its creditors or any class of them, ceases to carry on business or claims the benefit of any statutory moratorium, or undergoes any similar or equivalent process in any jurisdiction. </li>
        </ol>
        <p>19.5. Customer agrees to immediately give notice to Welles Partners Pte Ltd of any Change of Control of Customer. Without affecting any other right or remedy available to it, Welles Partners Pte Ltd may terminate these Terms in whole or part with immediate effect by giving notice to Customer if Customer undergoes a Change of Control such that Control of Customer passes to any person that Welles Partners Pte Ltd deems (acting reasonably) to be a competitor of Welles Partners Pte Ltd; without limiting the foregoing, following any Change of Control of Customer, Customer undertakes to enter good faith discussions concerning any revisions or amendments to these Terms that Welles Partners Pte Ltd determines may be necessary to reflect any such Change of Control. </p>
        <p>19.6. The Parties acknowledge and agree that failure by Customer to pay any sums when due shall constitute a <b>“material breach”</b> for the purposes of Clause 19.4(a). </p>

        <h5>20. CONSEQUENCES OF TERMINATION </h5>
        <p>20.1. On termination of these Terms: </p>
        <ol type="a">
            <li> notwithstanding Clauses 4.1 and 4.2, Customer shall promptly (and in any event within ten (10) Business Days of the date of termination) pay Welles Partners Pte Ltd any outstanding balances owing to Welles Partners Pte Ltd under these Terms or relevant part thereof, including any final invoice issued in accordance with Clause 4.6 (provided that payment of sums owing under any such final invoice shall be paid to the Welles Partners Pte Ltd within ten (10) Business Days of the date of receipt thereof); </li>
            <li> any and all licences, permissions and authorisations granted to Customer and/or its Authorised Users by Welles Partners Pte Ltd under these Terms will terminate automatically; and </li>
            <li> each Party will promptly return all Confidential Information received from the other Party, together with all copies, or certify in writing that all such Confidential Information and copies thereof have been destroyed. </li>
        </ol>
        <p>20.2. Any obligation to return, destroy or permanently erase Confidential Information outlined in Clause 20.1(c) shall not apply: </p>
        <ol type="a">
            <li> in respect of one (1) copy of the Customer Data, that Welles Partners Pte Ltd may be entitled to retain as necessary to comply with any legal, regulatory, judicial, audit, or internal compliance requirements; and </li>
            <li> to any Confidential Information or Customer Data that is retained by Welles Partners Pte Ltd on electronic back-up media made in the ordinary course of business and from which it cannot readily be isolated from other information and deleted, provided that, in each case, the applicable provisions of these Terms relating to data security and Confidential Information shall continue to apply to any such Confidential Information and/or Customer Data. </li>
        </ol>
        <p>20.3. Any provision of these Terms that either expressly or by implication is intended to come into or continue in force on or after termination of these Terms shall remain in full force and effect, including: Clause 1 (Interpretation), Clause 4 (Fees), Clause 6 (Ownership), Clause 7 (Use of Deliverables), Clause 8 (Restrictions), Clause 11 (Customer Data), Clause 12 (Data Protection), Clause 13 (Third Party Content), Clause 15 (Confidentiality), Clause 16 (Non-solicitation), Clause 17 (Limitation of Liability), Clause 18 (Third Party reliance indemnity), Clause 20 (Consequences of termination), Clause 21.6 (Waiver), Clause 21.7 (Rights and remedies), Clause 21.8 (Severance), Clause 21.9 (Entire agreement), Clause 21.10 (No partnership or agency), Clause 21.11 (Rights of third parties), Clause 21.12 (Notices), Clause 21.15 (Publicity), Clause 21.17 (Dispute Resolution), Clause 21.18 (Governing law) and Clause 21.19 (Arbitration). </p>
        <p>20.4. Termination of these Terms shall not affect any rights, remedies, obligations or liabilities of the Parties that have accrued up to the date of termination, including the right to claim damages in respect of any breach of these Terms which existed at or before the date of termination. </p>

        <h5>21. GENERAL </h5>
        <p>21.1. Open source software. Certain elements of the AML/CFT Platform and Forensics Platform are subject to <b>“open source”</b> or <b>“free software licenses”</b> (for the purposes of this Clause 21.1, <b>“Open Source Software”</b>). Customer acknowledges that certain elements of such Open Source Software are owned by third parties. No Open Source Software is licensed under any provision of these Terms under which Welles Partners Pte Ltd grants Customer any licence to use the AML/CFT Platform or Forensics Platform; instead, each item of Open Source Software is licensed under the terms of the end-user licence that accompanies such Open Source Software (for the purposes of this Clause 21.1, each an <b>“OSS Licence”</b>). Nothing in these Terms limits Customer’s rights under, or grants Customer rights that supersede, the terms and conditions of any OSS Licence. If required by any OSS Licence, Welles Partners Pte Ltd shall make available relevant pieces of Open Source Software available upon written request. </p>
        <p>21.2. Force Majeure. No Party will be in breach of these Terms nor liable for any failure to perform its obligations under these Terms if that failure results from circumstances beyond its reasonable control (for the purposes of this Clause 21.2, a <b>“Force Majeure Event”</b>). If a Force Majeure Event continues for three (3) months, the unaffected Party may terminate these Terms by giving thirty (30) days’ written notice to the other Party. </p>
        <p>21.3. Subcontracting. Welles Partners Pte Ltd may engage any third party to perform its obligations under these Terms, provided that Welles Partners Pte Ltd shall remain fully liable to Customer for performance of such obligations (subject to the exclusions and limitations outlined herein). </p>
        <p>21.4. Assignment. The Customer will not assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under the Agreement, without the prior written consent of Welles Partners Pte Ltd. </p>
        <p>21.5. Variation. Subject to Clause 3, no variation of the Agreement shall be effective unless it is in writing and signed by the Parties (or their authorised representatives). </p>
        <p>21.6. Waiver. A waiver of any right or remedy under these Terms or by law is only effective if given in writing and shall not be deemed a waiver of any subsequent breach or default. A failure or delay by a Party to exercise any right or remedy provided under these Terms or by law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided under these Terms or by law shall prevent or restrict the further exercise of that or any other right or remedy. </p>
        <p>21.7. Rights and Remedies. The rights and remedies provided under these Terms are in addition to, and not exclusive of, any rights or remedies provided by law. </p>
        <p>21.8. Severance. If any provision or part-provision of these Terms shall be held to be invalid, illegal, void or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this Clause 21.8 shall not affect the validity and enforceability of the rest of these Terms. If one Party gives notice to the other of the possibility that any provision or part-provision of these Terms is invalid, illegal or unenforceable, the Parties shall negotiate in good faith to amend such provision so that, as amended, it is legal, valid and enforceable, and, to the greatest extent possible, achieves the intended commercial result of the original provision. </p>
        <p>21.9. Entire agreement. Except as stated otherwise in an Engagement Letter, the Engagement Letter(s) and these Terms constitute the entire agreement and understanding between the Parties relating to the matters contemplated by the Agreement and supersedes all previous agreements (if any and whether in writing or not) between the Parties in relation to such matters, including any standard terms of the Customer. The Parties acknowledge and agree that, except as otherwise expressly provided for in the Agreement, they are not entering into the Agreement on the basis of, and are not relying on and have not relied on, any statement, representation, warranty or other provision (in any case whether oral, written, expressed or implied) made, given, or agreed to by any person (whether a Party to these Terms or not) in relation to the subject matter of these Terms, provided that nothing in the Agreement shall exclude any Party from liability for fraud or fraudulent misrepresentation. </p>
        <p>21.10. No Partnership or Agency. Nothing in these Terms is intended to, or shall be deemed to, establish any partnership or joint venture between any of the Parties, constitute any Party the agent of another Party, or authorise any Party to make or enter into any commitments for or on behalf of any other Party. Each Party confirms it is acting on its own behalf and not for the benefit of any other person. </p>
        <p>21.11. Rights of Third Parties. A person who is not a Party to these Terms shall not be entitled to enforce any of its terms under the Contracts (Rights of Third Parties) Act 1999. </p>
        <p>21.12. Notices. Any notice required to be given under these Terms will be in writing and will be sent to the email addresses for contractual notices set out in an Engagement Letter. Notices will be deemed to have been received at the time of transmission as shown by the sender’s records (or if sent outside business hours, at 9am on the first Business Day following dispatch). A Party may change its details given on an Engagement Letter by giving written notice to the other Party. </p>
        <p>21.13. Counterparts. The Agreement (or any part thereof requiring signed execution) may be signed in any number of counterparts and by the Parties on separate counterparts, each of which, when executed and delivered by a Party, shall be an original and such counterparts taken together shall constitute one and the same agreement. Electronic copies of signatures by the authorised representatives of the Parties (e.g., PDF scans) are enforceable just as though they were original wet-ink signatures. </p>
        <p>21.14. Order of Precedence. In the event of a conflict or inconsistency between these Terms and an Engagement Letter, the terms of the Engagement Letter shall take precedence. </p>
        <p>21.15. Publicity. The Customer hereby agrees that Welles Partners Pte Ltd may use the Customer’s company name and any associated trade marks on Welles Partners Pte Ltd’s website or in other marketing materials for the sole purpose of promoting the AML/CFT Platform, the Forensics Platform and any other services offered by Welles Partners Pte Ltd. If the Customer wishes to restrict the use of its company name and/or trade marks in this way, it should email <a href="mailto:info@welles.sg">info@welles.sg</a>. </p>
        <p>21.16. Anti-Bribery. The Parties confirm that they will comply with all applicable laws, regulations, codes and sanctions relating to anti-bribery and anti-corruption including but not limited to the Bribery Act 2010 and the Modern Slavery Act 2015 in the performance of their obligations under this Agreement. </p>
        <p>21.17. Dispute Resolution. In the event of any Dispute, the Parties shall seek to settle that Dispute by mediation in accordance with the Singapore Court of International Arbitration Mediation Procedure (the <b>“Procedure”</b>), and such Procedure is deemed to be incorporated by reference into the terms of this Agreement. </p>
        <p>21.18. Governing law. The Agreement and all matters arising from it (including any dispute relating to the existence, validity or termination of the Agreement or any contractual or non-contractual obligation) shall be governed by, and construed in accordance with the laws of Singapore. </p>
        <p>21.19. Arbitration. </p>
        <ol type="a">
            <li> Any Dispute that is not resolved using the Dispute Resolution procedure set out in Clause 21.17 shall be referred to and finally resolved by arbitration under the Singapore Court of International Arbitration Rules, which are deemed to be incorporated by reference into this clause. </li>
            <li> The number of arbitrators shall be one. </li>
            <li> The seat, or legal place, of arbitration shall be Singapore. </li>
            <li> The language to be used in the arbitral proceedings shall be English. </li>
            <li> The governing law of the contract shall be the substantive law of Singapore. </li>
        </ol>

        <h5>22. DEFINITIONS </h5>
        <p>0.1. The following definitions apply to these terms and conditions (the <b>“Terms”</b>): </p>
        <p>(a) <b>“Acceptable Use Policy”</b>: means the then-current acceptable use policy relating to the use of AML/CFT Platform and Forensics Platform that is provided to users thereof upon first access to the AML/CFT Platform and/or Forensics Platform (as applicable). </p>
        <p>(b) <b>“Access Protocols”</b>: means the user log-in credentials, together with the network link required to enable Authorised Users to set their password, necessary to access the AML/CFT Platform and/or the Forensics Platform (as applicable). </p>
        <p>(c) <b>“Affiliate”</b>: means any entity that directly or indirectly Controls, is Controlled by, or is under common Control with another entity. </p>
        <p>(d) <b>“Agreement”</b>: means these Terms and any applicable Engagement Letter(s). </p>
        <p>(e) <b>“AML/CFT Platform”</b>: means the software systems and interfaces owned, operated and/or licensed by Welles Partners Pte Ltd, which are used to examine the provenance of Cryptocurrency for the purposes of assessing the risk of a Cryptocurrency transaction being associated with illicit activity, together with any Welles Partners Pte Ltd Data comprised therein or accessed thereby. </p>
        <p>(f) <b>“AML/CFT Platform Package”</b>: means the commercial package relating to the AML/CFT Platform as selected by the Customer and as set out in the relevant Engagement Letter. </p>
        <p>(g) <b>“Authorised Users”</b>: means those employees, agents and independent contractors of the Customer who are authorised by the Customer to access the AML/CFT Platform and/or the Forensics Platform (as applicable) as agreed in an Engagement Letter. </p>
        <p>(h) <b>“Business Day”</b>: means any day which is not a Saturday, Sunday or public holiday in Singapore and on which the banks are open for business in Singapore. </p>
        <p>(i) <b>“Confidential Information”</b>: shall have the meaning ascribed to it in Clause 15.1 below. </p>
        <p>(j) <b>“Control”</b>: means the beneficial ownership of more than fifty percent (50%) of the issued share capital of a company or the legal power to direct or cause the direction of the general management of the company, and the expression <b>“Change of Control”</b> shall be construed accordingly. </p>
        <p>(k) <b>“Cryptocurrency”</b>: means any other peer-to-peer electronic money or payment network which uses cryptography to secure transactions, for example Bitcoin. </p>
        <p>(l) <b>“Customer”</b>: means the Party (other than Welles Partners Pte Ltd) who has executed an Engagement Letter to use the AML/CFT Platform, the Forensics Platform or both. </p>
        <p>(m) <b>“Customer Data”</b>: means any data, media, information or other content that is inputted by Customer or Authorised Users in using the AML/CFT Platform and/or the Forensics Platform (as applicable). </p>
        <p>(n) <b>“Deliverables”</b>: means: </p>
        <ol type="i">
            <li> the results of any use of AML/CFT Platform and/or Forensics Platform, including but not limited to any graphs showing chains of Cryptocurrency transactions together with all information displayed alongside or within such graphs; </li>
            <li> analyses or reports (including drafts thereof) produced by Welles Partners Pte Ltd under an Engagement Letter; and </li>
            <li> any other items identified as being a Deliverable in an Engagement Letter, in any form or media. </li>
        </ol>
        <p>(o) <b>“Dispute”</b>: means any dispute or difference arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination or any contractual or non-contractual obligation. </p>
        <p>(p) <b>“Documentation”</b>: means the manuals, product literature, instructions, schematics, and drawings prepared or published by Welles Partners Pte Ltd that describe or relate to AML/CFT Platform and/or the Forensics Platform and its installation, use, operation, features, functionality, capabilities and maintenance. </p>
        <p>(q) <b>“Effective Date”</b> means the date as set out in the Engagement Letter, or, if there is more than one Engagement Letter, the date as set out in the first Engagement Letter issued pursuant to these Terms. </p>
        <p>(r) <b>“Welles Partners Pte Ltd”</b>: means Welles Partners Pte Ltd (a publicly traded and  limited company incorporated and registered in Singapore with company number 201917162C whose registered office is at 20 KRAMAT LANE #02-05 UNITED HOUSE, SINGAPORE , 228773 Singapore.. </p>
        <p>(s) <b>“Welles Partners Pte Ltd Data”</b>: means any data, media, information or other content that is accessible via the AML/CFT Platform or Forensics Platform, but excludes any Customer Data. </p>
        <p>(t) <b>“Welles Partners Pte Ltd Personnel”</b>: means any of Welles Partners Pte Ltd’s employees, agents, consultants or contractors together with, as the context permits and requires, the equivalent of its Affiliates and/or subcontractors. </p>
        <p>(u) <b>“Engagement Letter”</b>: means an engagement letter signed by the Parties, which incorporates these Terms. </p>
        <p>(v) <b>“Fees”</b>: means any fees set out in an Engagement Letter for the AML/CFT Platform Package and/or Forensics Platform Package (together with any associated access rights), and/or any other services to be provided by Welles Partners Pte Ltd under that Engagement Letter (including, where relevant, any associated Deliverables). </p>
        <p>(w) <b>“Forensics Platform”</b>: means the software systems and interfaces owned, operated and/or licensed by Welles Partners Pte Ltd, which are used to enable the re-identification of pseudonymous Cryptocurrency users and map chains of Cryptocurrency transactions. </p>
        <p>(x) <b>“Forensics Platform Package”</b>: means the commercial package relating to the Forensics Platform as selected by the Customer and as set out in the relevant Engagement Letter. </p>
        <p>(y) <b>“Initial Term”</b>: means the period specified for the initial subscription term as set out in the Engagement Letter or, if there is more than one Engagement Letter, the date as set out in the first Engagement Letter relating to the Customer’s subscription to the AML/CFT Platform Package and/or Forensics Platform Package issued pursuant to these Terms. </p>
        <p>(z) <b>“Intellectual Property Rights”</b>: means all copyright and related rights, patents, rights to inventions, utility models, trade marks, service marks, trade, business and domain names, rights in trade dress or get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, rights in computer software, database rights, topography rights, moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world. </p>
        <p>(aa) <b>“Losses”</b>: means all losses, liabilities, damages, costs, claims, charges, demands, actions, proceedings and expenses (including legal and professional fees and disbursements and costs of investigation, litigation, settlement, judgment, interest and penalties). </p>
        <p>(bb) <b>“Renewal Term”</b>: means the period specified for renewal subscription terms (as applicable) in the relevant Engagement Letter, or if not stated in the Engagement Letter, twelve (12) months. </p>
        <p>(cc) <b>“Term”</b>: means the Initial Term and any Renewal Term(s). </p>
        <p>(dd) <b>“Third Party Content”</b>: means any and all content, data, media, information, software (including all Intellectual Property Rights relating thereto or subsisting therein) that is owned by and/or licensed from a third party. </p>


        <h4>Schedule:</h4> 
        <h4>Data Processing Addendum </h4>
        <br></br>
        <h5>Definitions </h5>
        <h5>1 The following definitions apply to this Schedule (Data Processing Addendum). </h5>
        <ol type="a">
            <li> The terms <b>“data processor”</b>, <b>“data controller”</b>, <b>“processing”</b>, <b>“personal data”</b> and <b>“Supervisory Authority”</b> shall each have the meaning ascribed to it in the Data Protection Legislation from time to time. </li>
            <li> <b>“Customer Personal Data”</b>: means any personal data that is comprised in Customer Data. </li>
            <li> <b>“Data Protection Legislation”</b>: means the EU General Data Protection Regulation 2016/679 (the <b>“GDPR”</b>) and the UK Data Protection Act 2018 (as may be amended). References to <b>“Articles”</b> or <b>“Chapters”</b> of the GDPR in this Data Processing Addendum shall be construed accordingly. </li>
            <li> <b>“Welles Partners Pte Ltd Personal Data”</b>: means any personal data that is comprised in the Welles Partners Pte Ltd Data that is held or controlled by Customer. </li>
            <li> <b>“Model Clauses”</b>: means the standard contractual clauses for the transfer of personal data from the EEA to Restricted Countries (controller to controller transfers) (Commission Decision C(2004)5721). </li>
            <li> <b>“Personal Data Breach”</b>: has the meaning given in the GDPR. </li>
            <li> <b>“Restricted Country”</b> means a country or territory outside the European Economic Area that has not been deemed to provide an adequate level of protection for Personal Data by the European Commission. </li>
            <li> <b>“Restricted Transfer”</b> means: (i) a transfer of Customer Personal Data from Customer to Welles Partners Pte Ltd in a Restricted Country; or (ii) an onward transfer of Customer Personal Data from Welles Partners Pte Ltd to a Subprocessor in a Restricted Country, (in each case) where such transfer would be prohibited by Data Protection Legislation without an appropriate safeguard in place. </li>
                <ol type="i">
                    <li> <b>“Subject Request”</b> means a written request made in accordance with applicable Data Protection Legislation from a data subject for any of the following: </li>
                    <li> requesting information concerning the processing of, or copies of, his or her personal data; </li>
                    <li> requiring the rectification of any inaccurate or incomplete personal data; </li>
                    <li> requiring the erasure of personal data; </li>
                    <li> restricting the processing of personal data; </li>
                    <li> exercising his or her right to obtain and reuse their personal data for their own purposes across different services; </li>
                    <li> objecting to the processing of his or her personal data; or </li>
                    <li> objecting to being subject to a decision based solely on automated processing, including profiles (including, for the avoidance of doubt, by way of the operation of AML/CFT Platform). </li>
                </ol>
            <li> <b>“Subprocessor”</b> means any third party appointed by or on behalf of Welles Partners Pte Ltd to Process Customer Personal Data. </li>
        </ol>

        <h4>Customer Personal Data </h4>
        <p>2 The Parties acknowledge and agree that Welles Partners Pte Ltd is expressly authorised to process the Customer Personal Data, as described below, in connection with the performance of the Agreement, which is composed of the following categories of personal data, the specified categories of data subject and the description of processing activities. </p>

        <h4>Categories of Data Categories of Data </h4>
        <p>Subject: Description of processing activities Cryptocurrency Personal Data </p>
        <p>Cryptocurrency addresses; Cryptocurrency transaction information; data available on publicly accessible Cryptocurrency transaction ledgers and blockchains; usernames; account IDs; names; email addresses; addresses; gender; age; data relating to association with known or suspected criminal individuals, entities or events; data relating to the perceived risk of an individual being involved with crime; and other personal data necessary for purposes of the prevention of fraud, misuse of services, or money laundering, or the prevention or detection of crime. </p>
        <p>Personal data consisting of information as to the commission or alleged commission by a data subject of any offence, or any proceedings for any offence committed or alleged to have been committed by a data subject, the disposal of such proceedings or the sentence of any court in such proceedings (all categories of sensitive data or special categories of data). </p>
        <p>Cryptocurrency users, including users of Cryptocurrency exchange services, Cryptocurrency payment processing services, Cryptocurrency wallet services and other Cryptocurrency services. </p>
        <p>To process such personal data to provide the AML/CFT Platform and/or the Forensics Platform and to perform the obligations outlined in the Agreement. </p>
        <p>Personnel Personal Data </p>
        <p>First and last name; title; position; employer; contact information (company, email, phone, physical business address). </p>
        <p>Authorised Users and other employees, agents and independent contractors of the Customer </p>
        <p>To process such personal data to provide Customer with access to the AML/CFT Platform and/or the Forensics Platform. </p>

        <h4>To manage the relationship between the Parties. </h4>
        <p>3 With respect to the Parties’ rights and obligations under the Agreement relating to the processing, collection or storage of Customer Personal Data, the Parties acknowledge and agree that in respect of the: </p>
        <ol type="a">
            <li> Cryptocurrency Personal Data category, the Customer is a data controller and Welles Partners Pte Ltd is a data controller in its own right; and </li>
            <li> Personnel Personal Data category, the Customer is a data controller and Welles Partners Pte Ltd is a data processor. </li>
        </ol>

        <h4>Personnel Personal Data </h4>
        <p>4 In respect of the Personnel Personal Data, Welles Partners Pte Ltd shall: </p>
        <ol type="a">
            <li> act only on instructions from Customer in relation to the processing of Personnel Personal Data (including those outlined in the Terms); </li>
            <li> comply with its obligations as a data processor of the Personnel Personal Data under the Data Protection Legislation; </li>
            <li> take reasonable steps to ensure the reliability of any Welles Partners Pte Ltd Personnel who process Personnel Personal Data, ensuring that all such individuals are subject to confidentiality undertakings or professional or statutory obligations of confidentiality; </li>
            <li> implement and maintain, at its cost and expense, appropriate technical and organisational measures in relation to the processing and security of Personnel Personal Data in accordance with Data Protection Legislation. Welles Partners Pte Ltd shall ensure that such technical and organisational measures are appropriate to the particular risks that are presented by its Processing activities, in particular to protect Personnel Personal Data from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access; </li>
            <li> prior to engaging any Subprocessor to carry out any processing activities in respect of the Personnel Personal Data, appoint the Subprocessor under a written contract including terms which offer at least an equivalent level of protection for Personnel Personal Data as those set out in this Paragrapgh 4. For the avoidance of doubt, Welles Partners Pte Ltd confirms that any Subprocessors already engaged by Welles Partners Pte Ltd as at the date of the Agreement, shall already have a written contract affording at least an equivalent level of protection for Personnel Personal Data as those set out in this Paragraph 4; </li>
            <li> taking into account the nature of the processing and the information available to it, provide Customer with such assistance as may be reasonably necessary and technically possible in the circumstances, to assist Customer in fulfilling its obligation to respond to Subject Requests. Welles Partners Pte Ltd shall promptly notify the Customer if it receives a Subject Request relating to the Personnel Personal Data; </li>
            <li> provide reasonable assistance to the Customer, at the Customer’s cost, with any data protection impact assessments, and prior consultations with Supervisory Authorities taking into account the nature of the processing by, and information available to, Welles Partners Pte Ltd; </li>
            <li> notify Customer without undue delay upon Welles Partners Pte Ltd becoming aware of a Personal Data Breach affecting any Personnel Personal Data, providing Customer with sufficient information (insofar as such information is, at such time, within Welles Partners Pte Ltd’s possession) to allow Customer to meet any obligations under Data Protection Legislation; </li>
            <li> subject to any requirement for Welles Partners Pte Ltd to retain Personnel Personal Data by applicable law, at the Customer’s written request, either delete or return all the Personnel Personal Data to the Customer within a reasonable time after the expiry or termination of the Agreement. Welles Partners Pte Ltd shall ensure that its Subprocessors shall also either delete or return such Personnel Personal Data in its possession; and </li>
            <li> make available to Customer no more than once per calendar year (except where instigated by a Supervisory Authority) on request such information as Welles Partners Pte Ltd (acting reasonably) considers appropriate in the circumstances to demonstrate its compliance with this Paragraph 4. In the event that Customer (acting reasonably) is able to provide documentary evidence that the information made available by Welles Partners Pte Ltd pursuant to this Paragraph 4(j) is not sufficient in the circumstances to demonstrate Welles Partners Pte Ltd’s compliance with Paragraph 4, Welles Partners Pte Ltd shall, subject to (i) a minimum of thirty (30) days’ notice, and (ii) reasonable confidentiality undertakings being given, permit the Customer to perform an audit of Welles Partners Pte Ltd’s compliance with this Paragraph 4 and Welles Partners Pte Ltd shall provide all reasonable assistance to the Customer in exercising the audit. For the avoidance of doubt, the audit shall not include providing the Customer with access to any data (whether Personal Data or otherwise) outside the scope of the Personnel Personal Data and as a result, the Customer acknowledges and agrees that access to Welles Partners Pte Ltd’s servers will be accordingly restricted. </li>
        </ol>

        <p>5 In respect of the Personnel Personal Data, the Customer warrants and represents on an ongoing basis that: </p>
        <ol type="a">
            <li> it has complied, and undertakes that it shall comply, with its obligations as a data controller of the Personnel Personal Data under the Data Protection Legislation (including ensuring Welles Partners Pte Ltd has a valid legal basis(es) for its processing of Personnel Personal Data as envisaged by the terms of these Terms); and </li>
            <li> the Personnel Personal Data does not contain any special categories of personal data referred to in Article 9(1) of the GDPR. </li>
        </ol>
        <h4>Cryptocurrency Personal Data </h4>
        <p>6 In respect of Cryptocurrency Personal Data: </p>
        <ol type="a">
            <li> in relation to any processing of that data carried out in direct connection with Customer’s use of  the AML/CFT Platform and/or the Forensics Platform (as applicable): </li>
            <ol type="i">
                <li> the Parties act as joint data controllers (within the meaning given to that term in Article 26 of the GDPR); and </li>
                <li> Customer warrants and represents on an ongoing basis that it has established a valid legal basis in respect of that processing of Cryptocurrency Personal Data, including the transfer of such data to Welles Partners Pte Ltd for such processing, and </li>
            </ol>
            <li> in relation to any processing of that data other than in direct connection with Customer’s use of the AML/CFT Platform and/or the Forensics Platform (as applicable), the Parties act as independent data controllers, and shall each comply with applicable Data Protection Legislation in respect of any such processing </li>
        </ol>
        <h4>Welles Partners Pte Ltd Personal Data </h4>
        <p>7 In respect of Welles Partners Pte Ltd Personal Data: </p>
        <ol type="a">
        <li> in relation to any processing of that data carried out in direct connection with Customer’s use of  the AML/CFT Platform and/or the Forensics Platform (as applicable): </li>
            <ol type="i">
                <li> the Parties act as joint data controllers (within the meaning given to that term in  Article 26 of the GDPR); and </li>
                <li> Welles Partners Pte Ltd warrants and represents on an ongoing basis that it has established a valid legal basis in respect of that processing of Welles Partners Pte Ltd Personal Data, including the transfer of such data to Customer for the purposes permitted by Paragraph 7(b); and </li>
            </ol>
        <li> Customer agrees that it shall only process Welles Partners Pte Ltd Personal Data for the purposes of receiving the benefit of the AML/CFT Platform and/or the Forensics Platform (as applicable), and using the Deliverables (if any), in each case only for its internal business purposes and subject always to the applicable limitations and restrictions outlined in the Agreement, and </li>
        <li> in relation to any processing of that data other than in direct connection with Customer’s use of the AML/CFT Platform and/or the Forensics Platform (as applicable), the Parties act as independent data controllers (subject always to Paragraph 7(b)), and shall each comply with applicable Data Protection Legislation in respect of any such processing.  Welles Partners Pte Ltd Personal Data and Cryptocurrency Personal Data: general provisions </li>
        </ol>

        <p>8 It is acknowledged that for the purposes of Paragraphs 9 to 11, <b>“Relevant Data Subjects”</b> refers to those data subjects whose Cryptocurrency Personal Data is submitted to the AML/CFT Platform and/or the Forensics Platform (as applicable) to be supplemented with certain related Welles Partners Pte Ltd Personal Data. </p>

        <p>9 To the extent that it relates to the Cryptocurrency Personal Data and/or Welles Partners Pte Ltd Personal Data of a Relevant  Data Subject, each Party: </p>
        <ol type="a">
            <li> shall notify the other Party without undue delay of any actual (and not simply suspected or potential) personal data breach (as defined in the GDPR); and </li>
            <li> agrees to give the other reasonable assistance in the other’s handling of any such personal data breach (including reporting to Supervisory Authorities and/or Relevant Data Subjects where required by the GDPR). </li>
        </ol>
        <p>10 As between Welles Partners Pte Ltd and Customer, Customer shall be responsible for complying with any Relevant Data Subject’s exercise of their rights under Chapter III of the GDPR in connection with the processing covered by Paragraphs 6(a) and 7(a), provided that Welles Partners Pte Ltd shall provide Customer with reasonably necessary and available information and assistance to enable Customer to comply with its obligations under this Paragraph 10 and its obligations under the GDPR with respect to any such data subject request. </p>
        <p>11 Customer undertakes that it shall comply with its own, and shall assist Welles Partners Pte Ltd in discharging Welles Partners Pte Ltd’s, transparency obligations under Articles 13/14 of the GDPR, including by: </p>
        <ol type="a">
            <li> informing Relevant Data Subjects of the transfer of Cryptocurrency Personal Data to Welles Partners Pte Ltd; </li>
            <li> informing Relevant Data Subjects of the nature and consequences of the Parties’ processing activities covered by Paragraphs 6(a) and 7(a) (including the supplementing of Cryptocurrency Personal Data with certain related Welles Partners Pte Ltd Personal Data); and </li>
            <li> providing Relevant Data Subjects with a prominent link to Welles Partners Pte Ltd’s privacy policy from time-to-time. Restricted Transfers of Personal Data </li>
        </ol>
        <p>12 Welles Partners Pte Ltd shall only perform Restricted Transfers of Customer Personal Data in reliance on an adequacy decision or with an appropriate safeguard in place (including adoption of the Model Clauses) to ensure the continued treatment of Customer Personal Data in accordance with Data Protection Legislation or otherwise as permitted by Chapter V of the GDPR. </p>

        <p>13 Where relevant, e.g. where the Customer is based outside of the EEA, the Parties agree that they shall only perform Restricted Transfers of any Personal Data between them in reliance on an adequacy decision or with an appropriate safeguard in place to ensure the continued treatment of Personal Data in accordance with Data Protection Legislation or otherwise as permitted by Chapter V of the GDPR. In that regard and as applicable, the Parties shall be deemed to have entered into the Model Clauses under which: (i) Welles Partners Pte Ltd shall be the <b>“data exporter”</b> and the Customer shall be the <b>“data importer”</b>; (ii) the Customer shall be deemed to have selected option II(h)(i); and (iii) the contact points for Welles Partners Pte Ltd and the Customer shall be as set out in the relevant Engagement Letter. Promptly on request, Customer shall execute and return a fully-populated copy of any Model Clauses deemed to be entered into pursuant to this Paragraph 13, as such populated Model Clauses may be provided by Welles Partners Pte Ltd to Customer from time-to-time. </p>

        <h4>General Indemnity </h4>
        <p>14 Customer will indemnify, defend and hold Welles Partners Pte Ltd and its officers, directors, employees and agents harmless from and against any and all Losses arising from or in connection with any failure by Customer, its employees, consultants or agents to comply with any of its obligations under this Schedule (Data Processing Addendum) or the Data Protection Legislation. </p>
        </div>
    </Aux>
)

export default termsText;