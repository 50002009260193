import React, { Component } from 'react';
import classes from './StatMode.css';
import Aux from '../../../hoc/Aux/Aux';


import D3CodeWrapper from './d3CodeWrapper/d3CodeWrapper'
import StatModeIco from '../../../assets/images/stat-mode.png';

class FlowMode extends Component {
    state = {
        showSideDrawer: false
    }

    render() {
        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Row}>
                        <div>
                            <img src={StatModeIco} width='50px' alt="Stat Mode" />
                        </div>
                        <div>
                            <h2>&nbsp; StatMode</h2>
                        </div>
            
                    </div>
                    <div className={classes.RowJust}>
                        <p>Welles Charts(Stats and Network activity). The most trusted source for data on the bitcoin blockchain...</p>
                    </div>
                    

                </div>
                <div className={classes.Center}>
                    <D3CodeWrapper />
                </div>

                <div className={classes.App}>
                    <div className={classes.Row}>
                        <p>Provided by Welles Partners Pte. Ltd.</p>
                    </div>
                </div>
            </Aux>)
    }
}

export default FlowMode;