import React, { Component } from 'react';
import classes from './FlowMode.css';
import Aux from '../../../hoc/Aux/Aux';

import D3CodeWrapper from './d3CodeWrapper/d3CodeWrapper'
import FlowModeIco from '../../../assets/images/flow-mode.png';

import Input from '../../../components/UI/Input/Input';
import SearchButton from '../../../components/UI/SearchButton/SearchButton'


// ------------------------------------------------------------------
//                         ADDRESS VALIDATION
//
// specs: https://www.npmjs.com/package/wallet-address-validator
// ------------------------------------------------------------------
const currency = 'BTC';
const network_mode = 'mainnet';
// ------------------------------------------------------------------

class FlowMode extends Component {
    state = {
        controls: {
            input: {
                elementType: 'string',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Search for ADDR or TXN ID'
                },
                value: '3P3QsMVK89JBNqZQv5zMAKG8FK3kJM4rjt',
                validation: {
                    required: true,
                    btcAddr: true
                },
                valid: false,
                touched: false
            },
        },
        showSideDrawer: false,
        formIsValid: false
    }





    async checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.btcAddr) {
            isValid = (await this.validate_address(value, currency, network_mode)) && isValid
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        return isValid;
    }





    render() {
        const formElementsArray = [
            {
                id: 'input',
                config: this.state.controls.input
            }
        ];


        let form = formElementsArray.map(formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
        ));

        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Row}>
                        <div>
                            <img src={FlowModeIco} width='50px' alt="Flow Mode" />
                        </div>
                        <div>
                            <h2>&nbsp; FlowMode</h2>
                        </div>
                    </div>
                    <div className={classes.Row}>
                        <div className={classes.Input}>
                            {form}
                        </div>
                        <SearchButton disabled={!this.state.formIsValid} onClick={this.orderHandler}>Search</SearchButton>
                    </div>
                </div>
                <div className={classes.Center}>
                    <D3CodeWrapper />
                </div>
            </Aux>)
    }
}

export default FlowMode;