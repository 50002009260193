import React from 'react';
import classes from './Spinner.css';
import Aux from '../../../hoc/Aux/Aux'

const spinner = () => (
    <Aux>
        <div className={classes.LoadContainer}>
        <div className={classes.Loader} />
        </div>
        <div className={classes.Text}>
        <h5> Loading...</h5>
        </div>
    </Aux>
);

export default spinner;