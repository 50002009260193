import * as d3 from 'd3';
import d3Tip from 'd3-tip';




// to START collapsed write _children
// to START uncollasredv write children
import treeData from '../DataSample/TreeData.json'
//Change in d3Code function accordingily

const d3Code = (mainMountRef, refbutton) => {

  var risksRI = ["Miner", "Exchange", "Services", "Gambling", "Mixing", "Scammer", "Dark Web"]
  var color = d3.scaleOrdinal()
    .domain([0, 1, 2, 3, 4, 5, 6])
    .range(["#398D3F", "#56C36A", "#53FF5D", "#FCC338", "#EEA338", "#E75B5D", "#EA302A"])


  // Set the dimensions and margins of the diagram
  var margin = { top: 20, right: 20, bottom: 30, left: 20 },
    width = 900 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;


  let tip = d3Tip().attr('class', 'D3Tip')
    .html((d) => {
      var text = "<strong>BTC Address: </strong> <p style='color:red'>" + d.data.addr + "</p><br>";
      text += "<strong>Balanse: </strong> <span style='color:gray;text-transform:capitalize'>" + d3.format(",.2f")(d.data.bal) + "</span><br>";
      text += "<strong>Average RI: </strong> <span style='color:red'>" + d.data.avr_RI + "</span><br>";
      text += "<strong>Consisted of: </strong> <span style='color:red'>" + "</span><br>";
      text += `&nbsp;&nbsp;&nbsp; -  Miner  <span style='color:${color(0)}'>` + d3.format(".1%")(d.data.arr_per[0]) + "</span><br>";
      text += `&nbsp;&nbsp;&nbsp; - Exchange:  <span style='color:${color(1)}'>` + d3.format(".1%")(d.data.arr_per[1]) + "</span><br>";
      text += `&nbsp;&nbsp;&nbsp; - Services:  <span style='color:${color(2)}'>` + d3.format(".1%")(d.data.arr_per[2]) + "</span><br>";
      text += `&nbsp;&nbsp;&nbsp; - Gambling:  <span style='color:${color(3)}'>` + d3.format(".1%")(d.data.arr_per[3]) + "</span><br>";
      text += `&nbsp;&nbsp;&nbsp; - Mixing:  <span style='color:${color(4)}'>` + d3.format(".1%")(d.data.arr_per[4]) + "</span><br>";
      text += `&nbsp;&nbsp;&nbsp; - Scammer:  <span style='color:${color(5)}'>` + d3.format(".1%")(d.data.arr_per[5]) + "</span><br>";
      text += `&nbsp;&nbsp;&nbsp; - Dark Web: <span style='color:${color(6)}'>` + d3.format(".1%")(d.data.arr_per[6]) + "</span><br>";
      text += "<strong>Blocknumber:</strong> <span style='color:gray'>" + d3.format(",.0f")(d.data.block) + "</span><br>";
      return text;
    })













  var zoom = d3.zoom()
    .scaleExtent([0.2, 2])
    .on("zoom", zoomed);

  // console.log(zoom.scaleExtent()[0], zoom.scaleExtent()[1]);


  var drag = d3.drag()
    .subject(function (d) { return d; })
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended)


  
  function dragstarted(d) {
    d3.event.sourceEvent.stopPropagation();
    d3.select(this).classed("dragging", true);
  }

  function dragged(d) {
    d3.select(this).attr("cx", d.x = d3.event.x).attr("cy", d.y = d3.event.y);
  }

  function dragended(d) {
    d3.select(this).classed("dragging", false);
  }


  function slided(d) {
    zoom.scaleTo(svg, d3.select(this).property("value"));
  }






  // append the svg object to the body of the page
  // appends a 'group' element to 'svg'
  // moves the 'group' element to the top left margin
  var svg = d3.select(mainMountRef)
    .append("div")
    // Container class to make it responsive.
    .classed("svg-container", true)
    .append("svg")
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr("viewBox", `0 0 ${width + margin.right + margin.left} ${height + margin.top + margin.bottom}`)
    // Class to make it responsive.
    .classed("svg-content-responsive", true)
    // .attr("width", width + margin.right + margin.left)
    // .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.right + ")")
    .call(zoom);




  // //SVG TIP INSTALL
  // svg.call(tip)

  //DEFINE AREA WHERE I DRAG WORKS AND CURSOR TO MOVE
  var rect = svg.append("rect")
    .attr("width", width)
    .attr("height", height)
    .attr("rx", 10)
    .attr("ry", 10)
    .style("fill", "none")
    .style("pointer-events", "all")
    // .attr("cursor", "move");

  var container = svg.append("g")


  var slider = d3.select(mainMountRef).append("p").append("input")
  .datum({})
  .attr("type", "range")
  .attr("value", zoom.scaleExtent()[0.15])
  .attr("min", zoom.scaleExtent()[0])
  .attr("max", zoom.scaleExtent()[1])
  .attr("step", (zoom.scaleExtent()[1] - zoom.scaleExtent()[0]) / 100)
  .on("input", slided);


  function zoomed() {
    const currentTransform = d3.event.transform;
    containerNodesLines.attr("transform", currentTransform);
    slider.property("value", currentTransform.k);
  }




  //ADD MASH FIRST HORISONTAL
  // container.append("g")
  //   .attr("class", "x axis")
  //   .selectAll("line")
  //   .data(d3.range(0, width, 10))
  //   .enter().append("line")
  //   .attr("x1", function (d) { return d; })
  //   // .attr("y1", 0)
  //   .attr("x2", function (d) { return d; })
  //   .attr("y2", height);
  // //ADD MASH THEN VERTICAL
  // container.append("g")
  //   .attr("class", "y axis")
  //   .selectAll("line")
  //   .data(d3.range(0, height, 10))
  //   .enter().append("line")
  //   // .attr("x1", 0)
  //   .attr("y1", function (d) { return d; })
  //   .attr("x2", width)
  //   .attr("y2", function (d) { return d; });




  var legend = svg.append("g")
    .attr("transform", "translate(" + (width - 20) + ", " + (height - 140) + ")")


  risksRI.forEach((d, i) => {
    var legendRow = legend.append("g")
      .attr("transform", "translate( 0," + (i * 20) + ")")

    legendRow.append("rect")
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", color(i))
      .attr("stroke", "none")
    legendRow.append("text")
      .attr("x", -10)
      .attr("y", 10)
      .attr("fill", "black")
      .attr("text-anchor", "end")
      .style("text-transform", "capitalise")
      .text(d)
    console.log(i )
  })









  var containerNodesLines = svg.append("g")
    .attr("transform", "translate(" + 180 + "," + 0 + ")")
   



  containerNodesLines.call(tip)




  var i = 0,
    duration = 750,
    root;

  // declares a tree layout and assigns the size
  var treemap = d3.tree().size([height, width]);

  // Assigns parent, children, height, depth
  root = d3.hierarchy(treeData, function (d) { return d.children; });
  root.x0 = height / 2;
  root.y0 = 0;

  console.log(root)
  // Collapse after the second level
  root.children.forEach(collapse);

  update(root);

  // Collapse the node and all it's children
  function collapse(d) {
    if (d.children) {
      d._children = d.children
      d._children.forEach(collapse)
      d.children = null
    }
  }






  function update(source) {

    // Assigns the x and y position for the nodes
    var treeData = treemap(root);
    // Compute the new tree layout.
    var nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    // Normalize for fixed-depth.
    nodes.forEach(function (d) { d.y = d.depth * 180 });

    // ****************** Nodes section ***************************

    // Update the nodes...
    var node = containerNodesLines.selectAll('g.node')
      .data(nodes, function (d) { return d.id || (d.id = ++i); })
    // Enter any new modes at the parent's previous position.
    var nodeEnter = node.enter().append('g')
      .attr('class', 'node')
      .attr("transform", function (d) {
        return "translate(" + source.y0 + "," + source.x0 + ")";
      })
      .on('click', click)
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide)

    // Add Rectangles for the nodes
    nodeEnter.append('rect')
      .attr('class', 'node')
      .attr("rx", 4)
      .attr("ry", 4)
      .attr('width', 1e-6)
      .attr('height', 1e-6)
      .style("fill", function (d) {
        console.log("xxx" + JSON.stringify(d.data.avr_RI));
        return color(d.data.avr_RI - 1);
      })
      .style("stroke", function (d) {
        return d._children ? "blue" : "lightsteelblue";
      });

    // Add labels for the nodes
    nodeEnter.append('text')
      .attr("dy", ".35em")
      .attr("x", function (d) {
        return d.children || d._children ? -1 : 1;
      })
      .attr("text-anchor", function (d) {
        return d.children || d._children ? "middle" : "middle";
      })
      .text(function (d) {
        let newAddr = d.data.addr;
        newAddr = newAddr.substring(0, 3) + "..." + newAddr.slice(-3)
        return (newAddr + " | " + d.data.bal + "BTC")
      })
      .style("font-weight", 500)
      .style('fill', 'black')
      .attr('cursor', 'pointer');

    // UPDATE
    var nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate.transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + d.y + "," + d.x + ")";
      });

    // Update the node attributes and style
    nodeUpdate.select('rect.node')
      .attr('x', -60)
      .attr('y', -12)
      .attr('width', 120)
      .attr('height', 25)
      .style("stroke", function (d) {
        return d._children ? "blue" : "lightsteelblue";
      })
      .attr('cursor', 'pointer');


    // Remove any exiting nodes
    var nodeExit = node.exit().transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + source.y + "," + source.x + ")";
      })
      .remove();

    // On exit reduce the node Rectangless size to 0
    nodeExit.select('rect')
      .attr('width', 1e-6)
      .attr('height', 1e-6)

    // On exit reduce the opacity of text labels
    nodeExit.select('text')
      .style('fill-opacity', 1e-6);

    // ****************** links section ***************************

    // Update the links...
    var link = containerNodesLines.selectAll('path.link')
      .data(links, function (d) { return d.id; });

    // Enter any new links at the parent's previous position.
    var linkEnter = link.enter().insert('path', "g")
      .attr("class", "link")
      .attr('d', function (d) {
        var o = { x: source.x0, y: source.y0 }
        return diagonal(o, o)
      });

    // UPDATE
    var linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate.transition()
      .duration(duration)
      .attr('d', function (d) { return diagonal(d, d.parent) });

    // Remove any exiting links
    /* var linkExit =*/ link.exit().transition()
      .duration(duration)
      .attr('d', function (d) {
        var o = { x: source.x, y: source.y }
        return diagonal(o, o)
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // Creates a curved (diagonal) path from parent to the child nodes
    function diagonal(s, d) {

      let path = `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`

      return path
    }
    // Toggle children on click.
    function click(d) {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      update(d);
    }
  }

}


export default d3Code;