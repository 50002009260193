import axios from 'axios';
import qs from 'qs';

import config_par from '../../shared/config/config';

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {

    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const authAll = (grantType, urlCode) => {
    return async (dispatch) => {
        dispatch(authStart());

        let data = {
            grant_type: grantType,
            client_id: '3tcpkr59qru453hqgko3k1cd9k',
            code: urlCode,
            scope: 'profile',
            redirect_uri: config_par.cognitoUserPool.auth_redirect
        };
        let data_id;


        let url = config_par.cognitoUserPool.auth_token;

        //IF CREATE USER WITH SECRET ENABLED
        // let config = {
        //     auth: {
        //         username: '2fpgromo0oma9kmke7v6k8bt7b',
        //         password: '81stq56qonnrqen1mckt9t6aeftojujm6s72kc90k9ebivfq418'
        //     },
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     }
        // }

        //WITHOUT SECERT
        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        //DELETE DEFAULT HEADER
        delete axios.defaults.headers.common["Accept"]

        data = qs.stringify(data)

        let tokens_obj = await axios.post(url, data, config)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
                const token = {
                    id_token: response.data.id_token,
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    expires_in: expirationDate,
                    token_type: response.data.token_type
                };
                //set tokens and expiration data

                return token
            })
            .catch(err => {
                console.log(err)
                dispatch(authFail(err.response.data.error));
            });

        let url_id = config_par.cognitoUserPool.auth_user;

        let config_id = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + tokens_obj.access_token
            }
        }

        await axios.post(url_id, data_id, config_id)
            .then(response => {
                //set Ids
                localStorage.setItem('userId', response.data.username);
                localStorage.setItem('token', tokens_obj.access_token);
                localStorage.setItem('expirationDate', tokens_obj.expires_in);

                //Send Sucesfull apptication via reducer to change state
                dispatch(authSuccess(tokens_obj.access_token, response.data.username));
                //Check Time on LogIn
                dispatch(checkAuthTimeout((tokens_obj.expires_in.getTime() - new Date().getTime()) / 1000));
            })
            .catch(err => {
                console.log(err)
                dispatch(authFail(err.response.data.error));
            });

    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    };
};