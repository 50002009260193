import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItemFooter.css';

const navigationItem = (props) => (
    <li className={classes.NavigationItem} onClick={props.clicked}>
        <NavLink
            to={props.link}
            exact={props.exact}
            activeClassName={classes.active}>{props.children}</NavLink>
    </li>
);

export default navigationItem;