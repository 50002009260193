import * as d3 from 'd3';


// to START collapsed write _children
// to START uncollasredv write children
import data from '../DataSample/data.json'
//Change in d3Code function accordingily

const d3Code = (mainMountRef, refbutton) => {
  // Set the dimensions of the canvas / graph
  let margin = { top: 20, right: 20, bottom: 110, left: 20 },
    margin2 = { top: 430, right: 20, bottom: 30, left: 20 },

    width = 900 - margin.left - margin.right,
    height = 500 + 10 - margin.top - margin.bottom,
    height2 = 500 - margin2.top - margin2.bottom;


  // Parse the date / time
  var parseDate = d3.timeParse("%d-%b-%Y");
  var bisectDate = d3.bisector(function (d) { return parseDate(d.date) }).left;

  // Parse the value
  let parsePrice = (stringPrice) => {
    let intPrice = +stringPrice;
    return intPrice;
  }

  // Set the ranges
  var x = d3.scaleTime().range([0, width]),
    x2 = d3.scaleTime().range([0, width]),
    y = d3.scaleLinear().range([height, 0]),
    y2 = d3.scaleLinear().range([height2, 0]);

  // Define the axes
  var xAxis = d3.axisBottom(x),
    xAxis2 = d3.axisBottom(x2),
    yAxis = d3.axisLeft(y);

  var brush = d3.brushX()
    .extent([[0, 0], [width, height2]])
    .on("brush end", brushed);

  var zoom = d3.zoom()
    .scaleExtent([1, Infinity])
    .translateExtent([[0, 0], [width, height]])
    .extent([[0, 0], [width, height]])
    .on("zoom", zoomed);

  // Define the area(line)
  var area = d3.area()
    .curve(d3.curveMonotoneX)
    .x(function (d) { return x(parseDate(d.date)); })
    .y0(height)
    .y1(function (d) { return y(parsePrice(d.value)); });

  var area2 = d3.area()
    .curve(d3.curveMonotoneX)
    .x(function (d) { return x2(parseDate(d.date)); })
    .y0(height2)
    .y1(function (d) { return y2(parsePrice(d.value)); });

  // Adds the svg canvas
  var svg = d3.select(mainMountRef)
    .append("div")
    // Container class to make it responsive.
    .classed("svg-container", true)
    .append("svg")
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr('viewBox', `${0} ${0} ${width + 2 * margin.left + 2 * margin.right} ${height + margin.top + margin.bottom}`)
    // Class to make it responsive.
    .classed("svg-content-responsive", true)
    .append("g")
    .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

  svg.append("defs").append("clipPath")
    .attr("id", "clip")
    .append("rect")
    .attr("width", width)
    .attr("height", height);
    

  var focus = svg.append("g")
    .attr("class", "focus")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  var context = svg.append("g")
    .attr("class", "context")
    .attr("transform", "translate(" + margin2.left + "," + margin2.top + ")");

  // Get the data and alayse it

  // Scale the range of the data
  x.domain(d3.extent(data, function (d) { return parseDate(d.date); }));
  y.domain([0, d3.max(data, function (d) { return parsePrice(d.value); })]);
  x2.domain(x.domain());
  y2.domain(y.domain());

  // Add the valueline path.
  focus.append("path")
    .datum(data)
    .attr("class", "area")
    .attr("d", area)
    .style("cursor", "pointer")


// add the gridlines paralel to X axis
let yGrid = focus.append("g")
  .attr('class', 'grid')
  .attr("id", "grid-y")
  .style("opacity", 0.3)
  .style("stroke-dasharray", "3,3")
  .attr("data-visible", "false")
  .call(d3.axisLeft(y)
  .tickSizeOuter(0)
      .tickSize(-width)
      .tickFormat("")
  )

// // add the gridlines paralel to Y axis
// let xGrid = focus.append("g")
//   .attr('class', 'grid')     
//   .attr("id", "grid")
//   .attr("transform", "translate(0," + height + ")")
//     .style("opacity", 0.3)
//     .style("stroke-dasharray", "3,3")
//     .style("outline", "none")
//   .call(d3.axisBottom(x).ticks(3)
//       .tickSize(-height)
//       .tickFormat("")
//   )
  
  // Add the X Axis
  focus.append("g")
    .attr("class", "axis axis--x")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis);

  // Add the Y Axis
  focus.append("g")
    .attr("class", "axis axis--y")
    .call(yAxis);

  // append the x line
  focus.append("line")
    .attr("class", "x")
    .style("stroke", "blue")
    .style("stroke-dasharray", "3,3")
    // .style("opacity", 0.9)
    .attr("y1", 0)
    .attr("y2", height);

  // append the y line
  focus.append("line")
    .attr("class", "y")
    .style("stroke", "blue")
    .style("stroke-dasharray", "3,3")
    // .style("opacity", 0.9)
    .attr("x1", width)
    .attr("x2", width);

  // append the circle at the intersection
  focus.append("circle")
    .attr("class", "y")
    .style("fill", "none")
    .style("stroke", "blue")
    .attr("r", 2);

  // place the value at the intersection
  focus.append("text")
    .attr("class", "y1")
    .style("stroke", "white")
    .style("stroke-width", "3.5px")
    .style("opacity", 0.8)
    .attr("dx", 8)
    .attr("dy", "-.3em");
  focus.append("text")
    .attr("class", "y2")
    .attr("dx", 8)
    .attr("dy", "-.3em");

  // place the date at the intersection
  focus.append("text")
    .attr("class", "y3")
    .style("stroke", "white")
    .style("stroke-width", "3.5px")
    .style("opacity", 0.8)
    .attr("dx", 8)
    .attr("dy", "1em");
  focus.append("text")
    .attr("class", "y4")
    .attr("dx", 8)
    .attr("dy", "1em");

  // Add the valueline path area2
  context.append("path")
    .datum(data)
    .attr("class", "area")
    .attr("d", area2);

  // Add the X Axis area2
  context.append("g")
    .attr("class", "axis axis--x")
    .attr("transform", "translate(0," + height2 + ")")
    .call(xAxis2);

  // Add the Y Axis area2
  context.append("g")
    .attr("class", "brush")
    .call(brush)
    .call(brush.move, x.range());

  // append the rectangle to capture mouse zoom and move
  svg.append("rect")
    .attr("class", "zoom")
    .attr("width", width)
    .attr("height", height)
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
    .call(zoom)
    .style("fill", "none")
    .style("pointer-events", "all")
    .style("cursor", "crosshair")
    .on("mouseover", function () { focus.style("display", null); })
    // .on("mouseout", function() { focus.style("display", "none"); })
    .on("mousemove", mousemove);

  function brushed() {
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
    var s = d3.event.selection || x2.range();
    x.domain(s.map(x2.invert, x2));
    focus.select(".area").attr("d", area);
    focus.select(".axis--x").call(xAxis);
    svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
      .scale(width / (s[1] - s[0]))
      .translate(-s[0], 0));
  }

  function zoomed() {
    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
    var t = d3.event.transform;
    x.domain(t.rescaleX(x2).domain());
    focus.select(".area").attr("d", area);
    focus.select(".axis--x").call(xAxis);
    context.select(".brush").call(brush.move, x.range().map(t.invertX, t));

    // //Rescale on zoom paralel to X-axis GRID
    // xGrid.call(
    //   d3.axisBottom(x)
    //       .scale(d3.event.transform.rescaleX(x))
    //       .ticks(5)
    //       .tickSize(-width)
    //       .tickFormat("")
    //   )

    //   //Rescale on zoom paralel to Y-axis GRID
    // xGrid.call(
    //   d3.axisBottom(y)
    //       .scale(d3.event.transform.rescaleY(y))
    //       .ticks(5)
    //       .tickSize(-(2*height))
    //       .tickFormat("")
    //   )

  }

  function mousemove() {
    var x0 = x.invert(d3.mouse(this)[0]),
      i = bisectDate(data, x0, 1),
      d0 = data[i - 1],
      d1 = data[i],
      d = x0 - d0.date > d1.date - x0 ? d1 : d0;
    console.log(x(parseDate(d.date)))

    focus.select("circle.y")
      .attr("transform", "translate(" + x(parseDate(d.date)) + "," + y(d.value) + ")")

    focus.select("text.y1")
      .attr("transform",
        "translate(" + x(parseDate(d.date)) + "," +
        y(d.value) + ")")
      .text(d.value);

    focus.select("text.y2")
      .attr("transform",
        "translate(" + x(parseDate(d.date)) + "," +
        y(d.value) + ")")
      .text(d.value);

    focus.select("text.y3")
      .attr("transform",
        "translate(" + x(parseDate(d.date)) + "," +
        y(d.value) + ")")
      .text(d.date);

    focus.select("text.y4")
      .attr("transform",
        "translate(" + x(parseDate(d.date)) + "," +
        y(d.value) + ")")
      .text(d.date);

    focus.select(".x")
      .attr("transform",
        "translate(" + x(parseDate(d.date)) + "," +
        y(d.value) + ")")
      .attr("y2", height - y(d.value));

    focus.select(".y")
      .attr("transform",
        "translate(" + width * -1 + "," +
        y(d.value) + ")")
      .attr("x2", width + width);
  }

  function type(d) {
    d.date = parseDate(d.date);
    d.value = +d.value;
    return d;
  }

}

export default d3Code;