import * as actionTypes from './actionTypes';
import axios from 'axios';

import axiosFeedback from '../../containers/PagesApp/Feedback/axiosFeedback'

export const quickcheckAddressSuccess = (id, orderData) => {
    return {
        type: actionTypes.QUICKCHECK_ADDRESS_SUCCESS,
        orderId: id,
        orderData: orderData
    };
};

export const quickcheckAddressFail = (error) => {
    return {
        type: actionTypes.QUICKCHECK_ADDRESS_FAIL,
        error: error
    };
}

export const quickcheckAddressStart = () => {
    return {
        type: actionTypes.QUICKCHECK_ADDRESS_START
    };
};

export const quickcheckAddress = (orderData, token, userId) => {
    return dispatch => {
        dispatch(quickcheckAddressStart());

        // DATA TEMPLATE
        // let orderData = {
        //     "addr_btc": "1CnaZKBTawPcbVUhowXyHzVPNrti1bKcws",
        //     "date_query": "Friday, July 26, 2019, 9:41:04 PM",
        //     "result_query": "wantToKnow",
        //     "user_id": "korisnik_2"
        // };

        let url = 'https://2342fbi4ni.execute-api.us-east-1.amazonaws.com/FirstApi';

        //DEFINE HEADER NO-AUTH
        let config = {
            headers: {
                'Content-Type': 'application/application-json',
            }
        }

        //DELETE DEFAULT HEADER
        delete axios.defaults.headers.common["Accept"]


        console.log(orderData)

        axios.post(url, orderData, config)
            .then(response => {
                console.log(response.data);
                dispatch(waitForFewSecondsAndFetch(token, userId));
            })
            .catch(error => {
                console.log(error)
                dispatch(quickcheckAddressFail(error));
            })
    };
};

export const quickcheckAddressDel = (orderDeleteData, token, userId) => {
    // console.log( userId ); 
    return dispatch => {
        dispatch(quickcheckAddressStart());



        // DATA TEMPLATE
        // let orderDeleteData = {
        //     "addr_btc": "1CnaZKBTawPcbVUhowXyHzVPNrti1bKcws",
        //     "date_query": "Friday, July 26, 2019, 9:41:04 PM",
        // };

        let url = 'https://2342fbi4ni.execute-api.us-east-1.amazonaws.com/FirstApi/api_userdb_del';

        //DEFINE HEADER NO-AUTH
        let config = {
            headers: {
                'Content-Type': 'application/application-json',
            }
        }

        //DELETE DEFAULT HEADER
        delete axios.defaults.headers.common["Accept"]


        console.log(orderDeleteData)

        axios.post(url, orderDeleteData, config)
            .then(response => {

                dispatch(fetchAddresses(token, userId));
            })
            .catch(error => {
                dispatch(quickcheckAddressFail(error));
            });
    };
};


export const sendFeedback = (signUserData, token, userId) => {
    // console.log( userId ); 
    return dispatch => {
        dispatch(quickcheckAddressStart());
        //MongoDB
        //axios.delete( '/addressesList.json?auth=' + token, orderData )
        //firebase speciphic     

        axiosFeedback.post('', signUserData)
            .then(response => {

                dispatch(waitForFewSeconds(token, userId));
            })
            .catch(error => {
                dispatch(quickcheckAddressFail(error));
            });
    };
};

//Fetching from server quickcheck addresses that user has 
//checked in past.
export const fetchAddressesSuccess = (quickcheckAddresses) => {
    return {
        type: actionTypes.FETCH_ADDRESSES_SUCCESS,
        quickcheckAddresses: quickcheckAddresses
    };
};

export const fetchAddressesFail = (error) => {
    return {
        type: actionTypes.FETCH_ADDRESSES_FAIL,
        error: error
    };
};

export const fetchAddressesStart = () => {
    return {
        type: actionTypes.FETCH_ADDRESSES_START
    };
};

export const fetchAddresses = (token, userId) => {
    return dispatch => {
        dispatch(fetchAddressesStart());



        // DATA TEMPLATE
        // let orderData = {
        //     "addr_btc": "1CnaZKBTawPcbVUhowXyHzVPNrti1bKcws",
        //     "date_query": "Friday, July 26, 2019, 9:41:04 PM",
        //     "result_query": "wantToKnow",
        //     "user_id": "korisnik_2"
        // };

        let url = 'https://2342fbi4ni.execute-api.us-east-1.amazonaws.com/FirstApi/api_userdb';

        //DEFINE HEADER NO-AUTH
        let config = {
            headers: {
                'Content-Type': 'application/application-json',
            }
        }

        //DELETE DEFAULT HEADER
        delete axios.defaults.headers.common["Accept"]

        let orderData = { "user_id": userId }
        console.log(orderData)

        axios.post(url, orderData, config)
            .then(res => {
                console.log(res.data.body.items_found)
                const fetchedAddresses = [];
                for (let key in res.data.body.items_found) {
                    fetchedAddresses.push({
                        ...res.data.body.items_found[key],
                        id: key
                    });
                }
                dispatch(fetchAddressesSuccess(fetchedAddresses));
            })
            .catch(err => {
                dispatch(fetchAddressesFail(err));
            });
    };
};

//MODAL REALATED
export const purchaseInit = () => {
    return {
        type: actionTypes.PURCHASE_INIT
    };
};


export const stopRedirect = () => {
    return {
        type: actionTypes.REDIRECT_STOP
    };
};

export const startSpinner = () => {
    return {
        type: actionTypes.SPINNER_START
    };
};

// WAITING FUNCTION AND REDORECT TO TRUE

export const waitSuccess = (quickcheckAddresses) => {
    return {
        type: actionTypes.WAIT_SUCCESS,
        quickcheckAddresses: quickcheckAddresses
    };
};

export const waitFail = (error) => {
    return {
        type: actionTypes.WAIT_FAIL,
        error: error
    };
};

export const waitStart = () => {
    return {
        type: actionTypes.WAIT_START
    };
};

//THIS IS TIMEOUT GIVEN FOR SENDING FEEDBACK
export const waitForFewSeconds = (event) => {
    return dispatch => {
        dispatch(waitStart());
        delay(500)
            .then(res => {
                const fetchedAddresses = [];
                dispatch(waitSuccess(fetchedAddresses));
            })
            .catch(err => {
                dispatch(waitFail(err));
            });
    };
};

//THIS IS TIMEOUT GIVEN ON QUERY
export const waitForFewSecondsAndFetch = (token, userId) => {
    return dispatch => {
        dispatch(waitStart());
        delay(1200)
            .then(res => {
                dispatch(fetchAddresses(token, userId));
            })
            .catch(err => {
                dispatch(fetchAddressesFail(err));
            });
    };
};

function delay(t, v) {
    return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t)
    });
}
