import React, { Component } from 'react';
import classes from './KycMode.css';
import Aux from '../../../hoc/Aux/Aux';

import KycModeIco from '../../../assets/images/kyc-mode.png';

import Input from '../../../components/UI/Input/Input';
import SearchButton from '../../../components/UI/SearchButton/SearchButton'

import KycDemo from '../../../assets/images/demo/kyc-demo.png';
import UnderConstruction from '../../../assets/images/demo/construction.png';


// ------------------------------------------------------------------
//                         ADDRESS VALIDATION
//
// specs: https://www.npmjs.com/package/wallet-address-validator
// ------------------------------------------------------------------
const currency = 'BTC';
const network_mode = 'mainnet';
// ------------------------------------------------------------------

class KycMode extends Component {
    state = {
        controls: {
            input: {
                elementType: 'string',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Search for ADDR or TXN ID'
                },
                value: '3P3QsMVK89JBNqZQv5zMAKG8FK3kJM4rjt',
                validation: {
                    required: true,
                    btcAddr: true
                },
                valid: false,
                touched: false
            },
        },
        showSideDrawer: false,
        formIsValid: false
    }





    async checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.btcAddr) {
            isValid = (await this.validate_address(value, currency, network_mode)) && isValid
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        return isValid;
    }





    render() {
        const formElementsArray = [
            {
                id: 'input',
                config: this.state.controls.input
            }
        ];


        let form = formElementsArray.map(formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
        ));

        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Row}>
                        <div>
                            <img src={KycModeIco} width='50px' alt="Kyc Mode" />
                        </div>
                        <div>
                            <h2>&nbsp; KycMode</h2>
                        </div>
                    </div>
                    <div className={classes.Row}>
                        <div className={classes.Input}>
                            {form}
                        </div>
                        <SearchButton disabled={!this.state.formIsValid} onClick={this.orderHandler}>Search</SearchButton>
                    </div>
                    <div>
                        <img src={UnderConstruction} alt="Under construction Pic" />
                    </div>
                    <h1>Under Construction</h1>
                    <p>This page is under construction</p>
                    <p>Comming soon, follow us for update on social network! </p>
                </div>

                <div className={classes.Center}>
                    <img src={KycDemo} alt="Kyc Demo Pic" />
                </div>
            </Aux>)
    }
}

export default KycMode;