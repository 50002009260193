import React, { Component } from 'react';
import classes from './QuickMode.css';
import Aux from '../../../hoc/Aux/Aux';
import Input from '../../../components/UI/Input/Input';

//quick Check related
import QuickModeIco from '../../../assets/images/quick-mode.png';
import SearchButton from '../../../components/UI/SearchButton/SearchButton'
import RefreshButton from '../../../components/UI/RefreshButton/RefreshButton'
import QuickCheckListItem from '../../../components/UI/QuickCheckListItem/QuickCheckListItem'

//Redux
import { connect } from 'react-redux';
import axios from '../../../axios-orders';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../../store/actions/index';

//To wait 
import Spinner from '../../../components/UI/Spinner/Spinner';
import waValidator from 'wallet-address-validator';

import { updateObject } from '../../../shared/utility';



// ------------------------------------------------------------------
//                         ADDRESS VALIDATION
//
// specs: https://www.npmjs.com/package/wallet-address-validator
// ------------------------------------------------------------------
const currency = 'BTC';
const network_mode = 'mainnet';
// ------------------------------------------------------------------


class QuickMode extends Component {
    state = {
        controls: {
            input: {
                elementType: 'string',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Enter your BTC address'
                },
                value: '',
                validation: {
                    required: true,
                    btcAddr: true
                },
                valid: false,
                touched: false
            },
        },
        showSideDrawer: false,
        formIsValid: false
    }

    componentDidMount() {
        this.props.onFetchAddress(this.props.token, this.props.userId);
    }

    async inputChangedHandler(event, controlName) {
        const updatedControls = updateObject(this.state.controls, {
            [controlName]: updateObject(this.state.controls[controlName], {
                value: event.target.value,
                valid: await this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            })
        });

        let formIsValid = true;
        for (let controlName in updatedControls) {
            formIsValid = updatedControls[controlName].valid && formIsValid;
        }
        this.setState({ controls: updatedControls, formIsValid: formIsValid });
    }

    orderHandler = (event) => {
        event.preventDefault();
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        let today = new Date();
        let order = {};

        //remove first if when run live
        if (this.props.userId === null) {
            order = {
                "addr_btc": this.state.controls.input.value,
                "date_query": today.toLocaleString("en-US", options),
                //FAKE BACK-END PROCESS TO CHECK VALIDITY
                "result_query": "na",
                //REMOVE QUOTES WHEN GO LIVE
                "user_id": this.props.userId
                // "user_id": "korisnik_postman_1"

            }
        } else {
            order = {
                "addr_btc": this.state.controls.input.value,
                "date_query": today.toLocaleString("en-US", options),
                //FAKE BACK-END PROCESS TO CHECK VALIDITY
                "result_query": "na",
                //REMOVE QUOTES WHEN GO LIVE
                "user_id": this.props.userId
                // "user_id": "korisnik_postman_1"

            }
        }
        // DATA TEMPLATE
        // let order = {
        //     "addr_btc": "1CnaZKBTawPcbVUhowXyHzVPNrti1bKcws",
        //     "date_query": "Friday, July 26, 2019, 9:41:04 PM",
        //     "result_query": "wantToKnow",
        //     "user_id": "korisnik_2"
        // };
        this.props.onQuickCheck(order, this.props.token, this.props.userId);
    }



    deleteHandeler = (orderDeleteData) => {
        // event.preventDefault();

        // DATA TEMPLATE
        // let orderDeleteData = {
        //     "addr_btc": "1CnaZKBTawPcbVUhowXyHzVPNrti1bKcws",
        //     "date_query": "Friday, July 26, 2019, 9:41:04 PM",
        // };

        let usid = this.props.userId
        this.props.onQuickCheckDel(orderDeleteData, this.props.token, usid);

    }

    refreshHandeler = (event) => {
        // event.preventDefault();

        // DATA TEMPLATE
        // let orderDeleteData = {
        //     "addr_btc": "1CnaZKBTawPcbVUhowXyHzVPNrti1bKcws",
        //     "date_query": "Friday, July 26, 2019, 9:41:04 PM",
        // };

        let usid = this.props.userId
        this.props.onFetchAddress(this.props.token, usid);

    }


    //function to validate address
    validate_address = (address, currency, mode) => {
        return new Promise((resolve, reject) => {
            resolve(waValidator.validate(address, currency, mode));
        });
    };

    async checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.btcAddr) {
            isValid = (await this.validate_address(value, currency, network_mode)) && isValid
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        return isValid;
    }

    render() {
        let quickcheckAddresses = <Spinner />;
        if (!this.props.loading) {
            let obj = this.props.quickcheckAddresses;
            quickcheckAddresses = []
            for (let i = 0; i < obj.length; i++) {
                let objIndividual = obj[i];
                // console.log("AAAAA" + JSON.stringify(objIndividual))
                let del_par = {
                    "_id": objIndividual._id.S,
                    "date_query": objIndividual.date_query.S
                }
                quickcheckAddresses.push(
                    //MONGODB
                    // <QuickCheckListItem clicked={() => this.deleteHandeler("{params: { id: " + objIndividual['id'] + "}}")}
                    //FIREBASE

                    < QuickCheckListItem clicked={() => this.deleteHandeler(del_par)}
                        key={objIndividual._id.S}
                        addr={objIndividual.addr_btc.S}
                        //database stores string and I convert it to boolian
                        result={objIndividual.result_query.S < 5}
                        risk_index={"Transaction Risk Index: " + objIndividual.result_query.S}
                        date={objIndividual.date_query.S}
                    />
                )
            }
        }

        const formElementsArray = [
            {
                id: 'input',
                config: this.state.controls.input
            }
        ];

        let form = formElementsArray.map(formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
        ));

        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Row}>
                        <div>
                            <img src={QuickModeIco} width='50px' alt="Quick Mode" />
                        </div>
                        <div>
                            <h2>QuickMode</h2>
                        </div>
                    </div>
                    <div className={classes.Row}>
                        <div className={classes.Input}>
                            {form}
                        </div>
                        <RefreshButton disabled={false} onClick={this.refreshHandeler} />
                        <SearchButton disabled={!this.state.formIsValid} onClick={this.orderHandler} >Search</SearchButton>
                    </div>
                    <p>Please click the <strong>Refresh</strong> button, if you can't see BTC Address you Searched.</p>


                    <div className={classes.Colon}  >
                        {quickcheckAddresses}
                    </div>
                </div>
            </Aux>)
    }
}

const mapStateToProps = state => {
    return {
        quickcheckAddresses: state.order.quickcheckAddresses,
        loading: state.order.loading,
        token: state.auth.token,
        userId: state.auth.userId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchAddress: (token, userId) => dispatch(actions.fetchAddresses(token, userId)),
        onQuickCheck: (orderData, token, userId) => dispatch(actions.quickcheckAddress(orderData, token, userId)),
        onQuickCheckDel: (orderData, token, userId) => dispatch(actions.quickcheckAddressDel(orderData, token, userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(QuickMode, axios));
