import React from 'react';

import classes from './BlueButton.css'

const searchButton = (props) => (
    <div className={classes.SearchButton}>
       <button
        disabled={props.disabled}
        className={[classes.Button, classes[props.btnType]].join(' ')}
        onClick={props.onClick}>{props.children}</button>
    </div>
)

export default searchButton;
