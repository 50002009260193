import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import * as actions from '../../../store/actions/index';

import BlueButton from '../../../components/UI/BlueButton/BlueButton';
import Button from '../../../components/UI/Button/Button';
import classes from './Feedback.css';
import Aux from '../../../hoc/Aux/Aux';
import Input from '../../../components/UI/Input/Input';

import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import Spinner from '../../../components/UI/Spinner/Spinner';
import FeedBackIco from '../../../assets/images/feedback-1.png';

import axios from './axiosFeedback';

class SendFeedback extends Component {
    state = {
        controls: {
            asub: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Subject'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 10
                },
                valid: false,
                touched: false
            },
            bReasonOfFeedback: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'compliment', displayValue: 'Compliment' },
                        { value: 'suggestion', displayValue: 'Suggestion' },
                        { value: 'somethingIsNotQuiteRight', displayValue: 'Something is not quite right' },
                        { value: 'theFormDoesntWorkWell', displayValue: 'The form does not work well' },
                        { value: 'reportaBug', displayValue: 'Report a Bug' }
                    ]
                },
                value: 'compliment',
                validation: {},
                valid: true
            },
            ctext: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Describe your issue or share your ideas...',
                    rows: "10"
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 50
                },
                valid: false,
                touched: false,
                rows: "10"
            },
        },
        formIsValid: false,
        showSideDrawer: false,
    }

    submitHandler = (event) => {
        event.preventDefault();
        const signUserData = {
            sub: this.state.controls.asub.value,
            name: ' USER ID: ' + this.props.userId,
            phone: ' N/A ',
            email: ' N/A ',
            desc: `Reason Of Feedback  + ${this.state.controls.bReasonOfFeedback.value} + \n +  TEXT:  + ${this.state.controls.ctext.value}`
        }
        // console.log(this.signUserData)
        let usid = this.props.userId
        this.props.sendFeedbackNow(signUserData, this.props.token, usid);

    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandlerIn = (event, controlNameIn) => {
        const updatedControls = {
            ...this.state.controls,
            [controlNameIn]: {
                ...this.state.controls[controlNameIn],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlNameIn].validation),
                touched: true
            }
        };
        let formIsValid = true;
        for (let controlNameIn in updatedControls) {
            formIsValid = updatedControls[controlNameIn].valid && formIsValid;
        }

        this.setState({ controls: updatedControls, formIsValid: formIsValid });
    }

    render() {
        let formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        formElementsArray = formElementsArray.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

        let form = formElementsArray.map(formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangedHandlerIn(event, formElement.id, true)} />
        ));


        let authRedirect = null;
        if (this.props.redirect) {
            authRedirect = <Redirect to='/' />
        }

        let feedbackForm = (<div>
            <form onSubmit={this.submitHandler}>
                {form}
                <div className={classes.Center}>
                    <div className={classes.Row}>
                        <font size="2"> We will use the information that you give us to help address technical issues and to improve our services, subject to our <NavLink to="/terms"><b>Terms of Service</b></NavLink> and <NavLink to="/privacy"><b>Privacy Policy</b></NavLink>. (Subject must be minimum ten characters,and description minimum fifty characters long.)</font>
                    </div>
                </div>
                <div className={classes.Center}>
                    <div className={classes.Width}>
                        <div>
                        <Button btnType="Success" disabled={!(this.state.formIsValid)}>Send</Button>
                        </div>
                        <div>
                        <BlueButton onClick={() => { window.history.back() }}>Back</BlueButton>
                        </div>
                    </div >

                </div>
            </form>
        </div>)

        if (this.props.loading) {
            feedbackForm = <Spinner />
        }

        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Input}>
                        <div className={classes.Center}>
                            <div className={classes.Row}>
                                <div>
                                    <img src={FeedBackIco} width='50px' alt="Quick Mode" />
                                </div>
                                <div>
                                    <h2>FEEDBACK</h2>
                                </div>
                            </div>
                            <h4>Send Us Your Feedback</h4>
                        </div>
                        {authRedirect}
                        {feedbackForm}
                    </div>
                </div>
            </Aux>)
    }
}

const mapStateToProps = state => {
    return {
        quickcheckAddresses: state.order.quickcheckAddresses,
        redirect: state.order.redirect,
        loading: state.order.loading,
        token: state.auth.token,
        userId: state.auth.userId,
        authRedirectPath: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendFeedbackNow: (orderData, token, userId) => dispatch(actions.sendFeedback(orderData, token, userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(SendFeedback, axios));
