import React, { Component } from 'react';
import classes from './OfficialMode.css';
import Aux from '../../../hoc/Aux/Aux';


import D3CodeWrapper from './d3CodeWrapper/d3CodeWrapper'
import OfficialModeIco from '../../../assets/images/official-mode.png';

import FollowButtonOut from '../../../components/UI/SigninButtonToOut/SigninButtonToOut'
import SigninButton from '../../../components/UI/SigninButton/SigninButton'
import config from '../../../shared/config/config';

class QfficialMode extends Component {
    state = {
        showSideDrawer: false
    }

    render() {
        return (
            <Aux>
                <div className={classes.App}>
                    <div className={classes.Row}>
                        <div>
                            <img src={OfficialModeIco} width='50px' alt="Official Mode" />
                        </div>
                        <div>
                            <h2>&nbsp; Welcome</h2>
                        </div>

                    </div>
                    <div className={classes.RowJust}>
                        <p>Welles database is proprietary and its mission is to deliver auditable proof of identity for 25.12 million of bitcoin addresses that are connected to many of real world entities. This gives you the confidence to investigate, evaluate and pursue suspicious activity in cryptocurrencies.</p>
                    </div>
                    <div className={classes.RowJust}>
                        <p>Please look on the diagram below for more information on our database. We believe it will give you the confidence that your cryptodeposits are compliant ...</p>
                    </div>

                </div>
                <div className={classes.Center}>
                    <D3CodeWrapper />
                </div>

                <div className={classes.App}>

                    <div className={classes.Input}>

                        {(localStorage.getItem('userId') === null) ?
                            <div className={classes.Button} >
                                <FollowButtonOut link={config.cognitoUserPool.auth}>Sign in</FollowButtonOut>
                            </div>
                            :<SigninButton  link="/">Continue </SigninButton> 
                        }
                        <div className={classes.Button}>
                            <FollowButtonOut link="https://welles.sg">To our Website</FollowButtonOut>
                        </div>
                    </div>
                </div>
                <div className={classes.App}>
                    <div className={classes.Row}>
                        <p>Please proceed to Welles Partners Pte. Ltd APP or to Official Website to learn more.</p>
                    </div>
                </div>
            </Aux>)
    }
}

export default QfficialMode;