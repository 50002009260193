import React from 'react';

import classes from './RefreshButton.css'
import Refresh from '../../../assets/images/refresh.png';

const searchButton = (props) => (
    <div className={classes.SearchButton}>
       <button
        disabled={props.disabled}
        className={[classes.Button, classes[props.btnType]].join(' ')}
        onClick={props.onClick}><img src={Refresh} alt="Refresh" />{props.children}</button>
    </div>
)

export default searchButton;
