import React from 'react';

import classes from './NavigationItems.css';
// import NavigationItem from './NavigationItem/NavigationItem';
import WaffleButton from '../../UI/WaffleButton/WaffleButton'
import SigninButton from '../../UI/SigninButton/SigninButton'
import { SocialIcon } from 'react-social-icons';
import FollowButtonOut from '../../UI/SigninButtonToOut/SigninButtonToOut'

import config from '../../../shared/config/config'

const navigationItems = (props) => (
    <ul className={classes.NavigationItems}>
        {/* {props.isAuthenticated ? <NavigationItem link="/stat-mode">Stats</NavigationItem> : null} */}
        
        <div className={classes.Social}>
        <SocialIcon className={classes.Ico} url="https://twitter.com/WellesPartners" style={{ height: 25, width: 25 }} />
        <SocialIcon className={classes.Ico} url="https://linkedin.com/company/welles-partners" style={{ height: 25, width: 25 }} />
        <SocialIcon className={classes.Ico} url="https://youtube.com/channel/UCNqntAcbVMrqN7sPKo1Ng1A" style={{ height: 25, width: 25 }} />
        </div>

        {props.isAuthenticated ? <WaffleButton /> : null}
        {props.isAuthenticated
            ? <div className={classes.SignIn}><SigninButton  link="/log-out">Log out </SigninButton></div>
            : <FollowButtonOut link={config.cognitoUserPool.auth} >Sign In</FollowButtonOut>}
    </ul>
);

export default navigationItems;