import React from 'react';

import classes from './SigninButtonToOut.css'

const signinButton = (props) => (
    <li className={classes.SigninButton}>
        < a href={props.link}>{props.children}</a>
    </li>
)

export default signinButton;
